// istanbul ignore file
import {
  Box,
  Button,
  DialogPrimitives as Dialog,
  Flex,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { i18n } from 'src/i18n';
import kandjiLogo from '../assets/kandji-logo-neutral.svg';
import step1 from '../assets/onboarding-step-1.webp';
import step2 from '../assets/onboarding-step-2.webp';
import step3 from '../assets/onboarding-step-3.webp';

interface OnboardingDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const OnboardingDialog = ({
  open,
  onOpenChange,
}: OnboardingDialogProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      id: 'step-1',
      title: () =>
        i18n.t(
          'In Kandji, apps and groups of settings are managed via Library Items, and scoped to devices using Blueprints.',
        ),
      description: null,
      image: step1,
    },
    {
      id: 'step-2',
      title: () =>
        i18n.t(
          'Library Items contain apps and settings that can be deployed to your devices.',
        ),
      description: () =>
        i18n.t(
          'It contains all of the possible Library Items the device may receive.',
        ),
      image: step2,
    },
    {
      id: 'step-3',
      title: () =>
        i18n.t(
          'Blueprints determine which Library Items get deployed to which devices.',
        ),
      description: () =>
        i18n.t('User and device criteria can be used in the logic.'),
      image: step3,
    },
  ];

  const currentStep = steps[activeStep];

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        blurred
        css={{
          width: '90vw',
          maxWidth: '1240px',
          height: '90vh',
          maxHeight: '740px',

          overflow: 'auto',
        }}
        hideClose
      >
        <Dialog.Header>
          <Flex
            flow="column"
            css={{
              gap: '10px',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
            }}
          >
            <Flex
              gap="sm"
              alignItems="center"
              css={{
                '& span': {
                  color: 'var(--text-secondary, #505E71)',
                  textAlign: 'center',
                  fontFeatureSettings: "'liga' off",
                  fontSize: 'var(--font-size-H5, 14px)',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight:
                    'var(--font-line-height-med-20, 20px) /* 142.857% */',
                  textTransform: 'uppercase',
                },
              }}
            >
              <img src={kandjiLogo} alt="Kandji logo" />
              <span>{i18n.t('How Kandji works')}</span>
            </Flex>
            <Dialog.Title>{currentStep.title()}</Dialog.Title>
            {currentStep.description && (
              <Text
                css={{
                  alignSelf: 'stretch',
                  color: 'var(--text-primary, #1F272F)',
                  fontFeatureSettings: "'liga' off",
                  fontSize: 'var(--font-size-H4, 16px)',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight:
                    'var(--font-line-height-large-24, 24px) /* 150% */',
                  letterSpacing: '-0.4px',
                }}
              >
                {currentStep.description()}
              </Text>
            )}
          </Flex>
        </Dialog.Header>
        <OnboardingContent image={currentStep.image} step={activeStep + 1} />
        <Dialog.Footer>
          <OnboardingFooter
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            onNext={() => {
              if (activeStep < steps.length - 1) {
                setActiveStep(activeStep + 1);
              } else {
                onOpenChange(false);
              }
            }}
            isLastStep={activeStep === steps.length - 1}
          />
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const OnboardingContent = ({
  image,
  step,
}: {
  image: string;
  step: number;
}) => {
  return (
    <Flex
      flow="column"
      css={{
        padding: '24px',
        flex: 1,
        minHeight: 0,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <OnboardingImage src={image} alt={`Onboarding step ${step}`} />
    </Flex>
  );
};

const OnboardingFooter = ({
  activeStep,
  setActiveStep,
  onNext,
  isLastStep,
}: {
  activeStep: number;
  setActiveStep: (step: number) => void;
  onNext: () => void;
  isLastStep: boolean;
}) => {
  const steps = [
    { isActive: activeStep === 0, id: 'step-1' },
    { isActive: activeStep === 1, id: 'step-2' },
    { isActive: activeStep === 2, id: 'step-3' },
  ].map((step) => ({
    ...step,
    width: step.isActive ? '86px' : '26px',
  }));

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      css={{ width: '100%' }}
    >
      <Box css={{ flex: 1 }}>
        {activeStep > 0 && (
          <Button
            onClick={() => setActiveStep(activeStep - 1)}
            variant="subtle"
          >
            {i18n.t('Back')}
          </Button>
        )}
      </Box>
      <Flex gap="xs" justifyContent="center">
        {steps.map((step) => (
          <StepIndicator
            key={step.id}
            css={{
              width: step.width,
              '&::after': {
                transform: step.isActive ? 'scaleX(1)' : 'scaleX(0)',
              },
            }}
          />
        ))}
      </Flex>
      <Box css={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onNext} variant="primary">
          {isLastStep ? i18n.t("Let's go!") : i18n.t('Next')}
        </Button>
      </Box>
    </Flex>
  );
};

const StepIndicator = styled('div', {
  position: 'relative',
  height: '16px',
  borderRadius: '4px',
  backgroundColor: 'rgba(161, 173, 196, 0.24)',
  transition: 'width 300ms ease-in-out',
  overflow: 'hidden',

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#111',
    transformOrigin: 'left',
    transition: 'transform 300ms ease-in-out',
  },
});

const OnboardingImage = styled('img', {
  maxWidth: '90%',
  maxHeight: '70vh',
  objectFit: 'contain',
  width: 'auto',
  height: 'auto',
});
