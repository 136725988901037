import {
  Badge,
  Code,
  Flex,
  Icon,
  Text,
  Tooltip,
  css,
} from '@kandji-inc/nectar-ui';
import type { CellContext, HeaderContext } from '@tanstack/react-table';
import { i18n } from 'i18n';
import { Link } from 'react-router-dom';

import { links } from 'src/app/common/constants';
import { formatBytes, formatTime } from 'src/app/components/common/helpers';
import { highlightedText } from 'src/features/visibility/pulse/views/CreatePulseCheck/components/DeviceSearchList';

import deviceImagesMap from '../../../../components/common/image-device/map';
import type { PrismCategoryFilterProperties as ColFilters } from '../types/filter.types';
import type { DeviceMetadataSchema } from '../types/prism.types';

export const NULL_VALUE_N_DASH = '–';
export const ON_OFF_CELL_FILTER_OPTIONS = [
  { value: 'true', label: 'On' },
  { value: 'false', label: 'Off' },
  { type: 'divider' },
  { value: 'is_null', label: 'is blank' },
  { value: 'is_not_null', label: 'is not blank' },
] as const;
export const YES_NO_CELL_FILTER_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
  { type: 'divider' },
  { value: 'is_null', label: 'is blank' },
  { value: 'is_not_null', label: 'is not blank' },
] as const;

const truncatedCss = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  variants: {
    variant: {
      rowHeaderCell: {
        // need to add some number z-index to override default `z-index: auto`
        // and allow elements like anchor links to stack above its fixed `th`
        // container and be clickable
        zIndex: 1,
      },
    },
  },
});

const truncatedFlexCss = css({
  minWidth: 0,
});

const truncatedCodeCss = css({
  display: 'inline-flex',
  alignItems: 'center',
  maxWidth: '100%',
});

const truncatedTextCss = css({
  display: 'inline-block',
  width: '100%',
  truncate: 'ellipsis',
});

const naTextStyles = css({
  color: '$neutral60',
  fontSize: '$2',
  fontStyle: 'italic',
  fontWeight: '$regular',
});

export const CleanCopyText = ({
  text,
  searchTerm,
}: { text: string; searchTerm?: string }) => (
  <Text
    className={truncatedTextCss().className}
    onCopy={(e) => {
      // trim the copied text to remove the leading space
      // TODO: figure out how to test this
      e.clipboardData.setData('text/plain', text.trim());
      e.preventDefault();
    }}
  >
    {highlightedText({ text, term: searchTerm || '' })}
  </Text>
);

export function getDeviceIconByFamily(familyName: string | undefined) {
  if (familyName?.includes('iPad')) {
    return deviceImagesMap['iPad14,6'];
  }
  if (familyName?.includes('iPhone')) {
    return deviceImagesMap['iPhone15,2'];
  }
  if (familyName?.includes('AppleTV')) {
    return deviceImagesMap['AppleTV14,1'];
  }

  return deviceImagesMap['MacBookPro18,1'];
}

export function getDeviceCell<T extends DeviceMetadataSchema>(
  info: CellContext<T, string>,
  category?: string,
) {
  let device = info.getValue();

  const prefix = category ? `${category}.` : '';

  const deviceId = info.row.original[`${prefix}device_id`];
  const modelId = info.row.original.model_id;
  const deviceFamily = info.row.original.device__family;

  const search = info.table?.getState().globalFilter;

  if (!device) {
    device = NULL_VALUE_N_DASH;
  }

  const icon = deviceImagesMap[modelId] ?? getDeviceIconByFamily(deviceFamily);

  return (
    <Flex
      alignItems="center"
      gap="sm"
      className={truncatedFlexCss().className}
      data-testid="device_cell"
    >
      <img
        data-testid="device_icon_image"
        height="20"
        width="20"
        src={icon}
        alt={modelId ?? deviceFamily}
      />
      <Link
        className={
          truncatedCss({
            variant: 'rowHeaderCell',
          }).className
        }
        to={`${links.devices}/${deviceId}`}
      >
        {highlightedText({ text: device, term: search })}
      </Link>
    </Flex>
  );
}

export function getDeviceUserCell<T extends DeviceMetadataSchema>(
  info: CellContext<T, string>,
) {
  const userName = info.getValue();

  if (userName === null) {
    return NULL_VALUE_N_DASH;
  }

  const userId = info.row.original.device__user_id;
  const searchTerm = info.table?.getState().globalFilter;

  return (
    <Flex>
      <Link
        to={`/users/all/${userId}`}
        className={truncatedCss().className}
        data-testid="user_cell_link"
      >
        {highlightedText({ text: userName, term: searchTerm })}
      </Link>
    </Flex>
  );
}

export function getSpecificDeviceFamilyCell<T extends DeviceMetadataSchema>(
  info: CellContext<T, string>,
  deviceFamilies: Array<'Mac' | 'iPhone' | 'iPad' | 'AppleTV'>,
  cellType?:
    | 'string'
    | 'boolean'
    | 'code'
    | 'bytes'
    | 'on-off'
    | 'on-off-badge',
) {
  if (deviceFamilies.includes(info.row.original.device__family)) {
    // TODO: we can extend this to support other cell types when we're ready
    if (cellType === 'boolean') {
      return YesNoCell(info as any);
    }
    if (cellType === 'code') {
      return getCodeSnippetCell(info as any);
    }
    if (cellType === 'bytes') {
      return BytesCell(info as any);
    }
    if (cellType === 'on-off') {
      return OnOffCell(info as any);
    }
    if (cellType === 'on-off-badge') {
      return OnOffCell(info as any, true);
    }
    return TextCell(info as any);
  }

  return NaCell();
}

export const NaCell = () => (
  <Text className={naTextStyles().className}>
    {i18n.t('n/a', { _context: 'Short for not available' })}
  </Text>
);

export function getBlueprintCell<T extends DeviceMetadataSchema>(
  info: CellContext<T, string>,
) {
  const blueprint = info.getValue();

  if (blueprint === null) {
    return NULL_VALUE_N_DASH;
  }

  const blueprintId = info.row.original.blueprint_id;

  return (
    <Flex>
      <Link
        to={`${links.blueprints}/${blueprintId}`}
        className={truncatedCss().className}
        data-testid="blueprint_cell_link"
      >
        {blueprint}
      </Link>
    </Flex>
  );
}

/**
 * We have to do a little bit of weird logic here to get the correct value,
 *  react-table passes in an unexpected and undocumented empty object as
 *  the second argument to the cell function, so when we pass in a custom string
 *  to render, we have to check if the second argument is a string or not.
 * @param info - cellContext
 * @param cellContent - text content to display
 * @returns <Code> element with that displays cellContent, if provided, or info.getValue()
 */
export function getCodeSnippetCell<T extends DeviceMetadataSchema>(
  info: CellContext<T, string>,
  cellContent?: string | Record<string, unknown> | undefined,
) {
  const cellValue = info.getValue();

  if (cellValue === null) {
    return NULL_VALUE_N_DASH;
  }

  const value = typeof cellContent === 'string' ? cellContent : cellValue;

  return !value ? (
    ''
  ) : (
    <Code
      className={truncatedCodeCss().className}
      data-testid="code_cell"
      title={value}
    >
      {' '}
      <CleanCopyText text={value} />
    </Code>
  );
}

export function formatDate(date: string) {
  return formatTime(date);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function FlexWrap({ iconName, children }) {
  return iconName ? (
    <Flex gap="xs" alignItems="center">
      <Icon name={iconName} size="sm" style={{ flex: 'none' }} />
      {children}
    </Flex>
  ) : (
    children
  );
}

export function getHeaderDisplayNameFromMeta<Data>(
  infoOrMetaObj: HeaderContext<Data, unknown> & {
    displayName?: string;
    displayAs?: 'plain-text' | 'basic-span';
  },
) {
  const {
    column,
    displayAs = 'plain-text',
    displayName: metaObjDisplayName,
  } = infoOrMetaObj;

  const infoObjDisplayName = column
    ? column?.columnDef?.meta?.displayName
    : null;

  if (!infoObjDisplayName && !metaObjDisplayName) {
    return null;
  }

  const displayName = infoObjDisplayName || metaObjDisplayName;
  const iconName = column?.columnDef?.meta?.displayIcon;

  switch (displayAs) {
    case 'basic-span':
      return (
        <FlexWrap iconName={iconName}>
          <span>{displayName}</span>
        </FlexWrap>
      );
    case 'plain-text':
      return <FlexWrap iconName={iconName}>{displayName}</FlexWrap>;
    default:
      return <FlexWrap iconName={iconName}>{displayName}</FlexWrap>;
  }
}

export const OnOffCell = (
  info: CellContext<unknown, boolean>,
  withBadge: boolean = false,
) => {
  const value = info.getValue();
  if (value === undefined) {
    return '';
  }
  if (value !== null) {
    if (withBadge) {
      return value ? (
        <Badge color="green">{i18n.t('On')}</Badge>
      ) : (
        <Badge color="neutral">{i18n.t('Off')}</Badge>
      );
    }
    return value ? (
      <CleanCopyText text={i18n.t('On')} />
    ) : (
      <CleanCopyText text={i18n.t('Off')} />
    );
  }
  return <>{NULL_VALUE_N_DASH}</>;
};

export const YesNoCell = (info: CellContext<unknown, boolean>) => {
  const value = info.getValue();

  if (value != null) {
    return value ? i18n.t('Yes') : i18n.t('No');
  }
  return NULL_VALUE_N_DASH;
};

export const TextCell = (info: CellContext<unknown, string>) => {
  const value = info.getValue();
  if (value == null) {
    return NULL_VALUE_N_DASH;
  }
  return <CleanCopyText text={String(value)} />;
};

export const TagsCell = (info: CellContext<unknown, string>) => {
  const value = info.getValue();
  if (value == null) {
    return NULL_VALUE_N_DASH;
  }
  const tags = () => (
    <Flex gap="xs" flow="column">
      {value.split(',').map((tag) => (
        <Text key={tag}>{tag.trim()}</Text>
      ))}
    </Flex>
  );

  return (
    <Tooltip content={tags} side="right" css={{ zIndex: 4 }}>
      <Text>{value}</Text>
    </Tooltip>
  );
};

export const HighlightedTextCell = (info: CellContext<unknown, string>) => {
  const value = info.getValue();
  const searchTerm = info.table.getState().globalFilter;
  if (value == null) {
    return NULL_VALUE_N_DASH;
  }
  return <CleanCopyText text={String(value)} searchTerm={searchTerm} />;
};

export const DateCell = (info: CellContext<unknown, string>) => {
  const value = info.getValue();
  if (value == null || typeof value !== 'string') {
    return NULL_VALUE_N_DASH;
  }
  return <CleanCopyText text={formatDate(value)} />;
};

export const DateTimeCell = (info: CellContext<unknown, string>) => {
  const value = info.getValue();
  if (value == null || typeof value !== 'string') {
    return NULL_VALUE_N_DASH;
  }
  return <CleanCopyText text={formatDate(value)} />;
};

export const BytesCell = (info: CellContext<unknown, number>) => {
  const value = info.getValue();
  if (value == null) {
    return NULL_VALUE_N_DASH;
  }
  return typeof value === 'number' ? (
    <CleanCopyText text={formatBytes(value)} />
  ) : (
    ''
  );
};

const STATUSES = {
  ALL_CLEAR: { label: 'All Clear', color: 'green' },
  WARNING: { label: 'Warning', color: 'yellow' },
  NO_HISTORY: { label: 'No History', color: 'neutral' },
  OFFLINE: { label: 'Offline', color: 'yellow' },
};

export const AlertStatusCell = (info: CellContext<unknown, string>) => {
  const status = STATUSES[info.getValue()];
  if (status) {
    return <Badge color={status.color}>{status.label}</Badge>;
  }

  return null;
};

const LOST_MODE_STATUSES = {
  PENDING: { label: 'Pending', color: 'neutral' },
  ENABLED: { label: 'Enabled', color: 'yellow' },
  ERRORED: { label: 'Errored', color: 'red' },
};

export const LostModeStatusCell = (info: CellContext<unknown, string>) => {
  const value = info.getValue();
  if (value === 'DISABLED') {
    return NULL_VALUE_N_DASH;
  }
  const status = LOST_MODE_STATUSES[value];
  if (status) {
    return (
      <Badge data-testid="lost-mode-status-badge" color={status.color}>
        {status.label}
      </Badge>
    );
  }

  return NULL_VALUE_N_DASH;
};

export function extractFilterableColumns<
  ColumnDefList extends Array<{
    id?: string;
    meta?: {
      displayName?: string;
      filterType?: string;
    };
  }>,
>(columnDefList: ColumnDefList) {
  const filterableColumnDefs = columnDefList.filter((colDef) => {
    const colMeta = colDef.meta;
    return colMeta?.filterType && typeof colMeta.filterType === 'string';
  });

  const filterableColumnOptions = filterableColumnDefs
    .map((colDef) =>
      colDef.id
        ? {
            value: colDef.id,
            label: colDef.meta?.displayName || colDef.id,
          }
        : null,
    )
    .filter((def): def is { value: string; label: string } => !!def);

  return {
    columnDefs: filterableColumnDefs,
    filterOptions: filterableColumnOptions,
  };
}

export function getFilteredDisabled(filterState: {
  filterKey: string;
  columnFilters: Array<ColFilters['unionOf']['details']>;
}) {
  const hasColumnFilter = filterState?.columnFilters?.some(
    (filter) => filter.key === filterState.filterKey,
  );
  return hasColumnFilter;
}

export function getEnumFilterOptions(
  enumValues: string[],
): Array<{ value: string; label: string }> {
  return enumValues.map((value) => ({ value, label: value }));
}
