import { Button, DropdownMenu, Separator } from '@kandji-inc/nectar-ui';
import { usePermissions } from 'contexts/account';
import { i18n } from 'i18n';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';

import { isRemoteEligible, regExpMapper } from 'app/components/common/helpers';
import { getLibraryItemStatusesForComputer } from 'src/app/components/library/api';
import { useFlags } from 'src/config/feature-flags';
import { useLostMode } from 'src/hooks/useLostMode';

import { useMsdcConfigurationStatus } from '../library-items/items/app-store-apps/additional-settings/ms-authenticator/use-msdc-configuration-status';
import { apiTypes } from '../library-items/library/common';
import useActions from './actions';
import {
  versionTestForBypassCode,
  versionTestForShutdownDevice,
} from './constants';
import './styles.scss';

const getLibraryItemStatus = async (computer, filterByType) => {
  const statuses = await getLibraryItemStatusesForComputer(computer.id);
  return statuses.filter(({ type }) => !filterByType || type === filterByType);
};

const DeviceActions = (props) => {
  const permissions = usePermissions();
  const { computer } = props;
  const [menuIsShowing, setMenuIsShowing] = useState(false);
  const [libraryStatuses, setLibraryStatuses] = useState();
  const shouldDisableSetDeviceName = Boolean(
    libraryStatuses?.find(
      (status) =>
        status.type === apiTypes.DEVICE_NAME &&
        status.status.toLowerCase() !== 'excluded',
    ),
  );
  const actions = useActions();

  useEffect(() => {
    if (computer) {
      getLibraryItemStatus(computer)
        .then(setLibraryStatuses)
        .catch(() => /** Assume device name LI isn't installed?  */ {});
    }
  }, [computer]);

  const {
    activation_lock: activationLock,
    device_family: deviceFamily,
    device,
    enrollment_type: enrollmentType,
    erase_status: eraseStatus,
    has_file_vault_prk: hasFileVaultPRK,
    is_mdm: isMdm,
    is_supervised: isSupervised,
    lock_status: lockStatus,
    mdm_info: mdmInfo,
    model,
    os_version: osVersion,
    recovery_information: recoveryInformation,
  } = computer;

  const { activation_lock_supported: activationLockSupported } = activationLock;

  const {
    isLostModeUnset,
    isLostModePending,
    isLostModeEnabled,
    isLostModeDisabled,
    lostModeCommandId,
    isLostModeDisablePending,
  } = useLostMode(computer);

  const isSharedIPad = deviceFamily === 'iPad' && mdmInfo.IsMultiUser;

  // the only action present when `isErased` is true is "Delete Computer"
  const isErased = eraseStatus === 'yes';
  const isActive = eraseStatus === 'no' && lockStatus === 'no';
  const isLocked = lockStatus === 'yes';
  const isADE = enrollmentType === '4';
  const isRemoteDesktopEnabled =
    computer?.security_information?.remote_desktop_enabled;

  const isDeviceScopedForMsdc =
    libraryStatuses?.some(
      (status) =>
        status.name === 'Microsoft Authenticator' ||
        status.name === 'Microsoft Company Portal',
    ) || false;
  const { msdcConfigStatus, integration } = useMsdcConfigurationStatus();
  const isResetMsRegistrationEnabled =
    msdcConfigStatus === 'configured' && isDeviceScopedForMsdc;

  const flags = useFlags(['mdm-renew-profile']);

  // isPresent: whether or not the item is displayed
  // isDisabled: if displayed, whether or not the item is greyed out and inactive
  const menuOptions = [
    {
      label: i18n.t('View FileVault2 recovery key'),
      icon: 'eye',
      action: () => actions.onShowRecoveryKey(computer),
      isPresent: hasFileVaultPRK && deviceFamily === 'Mac',
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('View Activation Lock bypass code'),
      icon: 'eye',
      action: () => actions.onShowBypassCode(computer),
      isPresent:
        isSupervised &&
        deviceFamily !== 'AppleTV' &&
        regExpMapper[versionTestForBypassCode[deviceFamily]].test(osVersion),
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('View Recovery Lock password'),
      icon: 'eye',
      action: () => actions.onViewRecoveryLockPassword(computer),
      isPresent:
        deviceFamily === 'Mac' && recoveryInformation.password_has_been_set,
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Send blank push'),
      icon: 'paper-plane',
      action: () => actions.onSendBlankPush(computer),
      isPresent: !isErased && isMdm,
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Turn on Remote Desktop'),
      icon: 'desktop',
      action: () => actions.onEnableRemoteDesktop(computer),
      isPresent:
        isMdm &&
        deviceFamily === 'Mac' &&
        !isRemoteDesktopEnabled &&
        isRemoteEligible({ osVersion, isSupervised, isADE }),
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Turn off Remote Desktop'),
      icon: 'cancel-desktop',
      action: () => actions.onDisableRemoteDesktop(computer),
      isPresent:
        isMdm &&
        deviceFamily === 'Mac' &&
        isRemoteDesktopEnabled &&
        isRemoteEligible({ osVersion, isSupervised, isADE }),
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Set device name'),
      icon: 'pencil',
      action: () => actions.onSetMDMDeviceName(computer),
      isPresent:
        !isErased &&
        isMdm &&
        ((['iPhone', 'iPad', 'iPod'].includes(deviceFamily) && isSupervised) ||
          ['Mac', 'AppleTV'].includes(deviceFamily)),
      isDisabled:
        !permissions.canManageDevices ||
        !isActive ||
        shouldDisableSetDeviceName,
    },
    {
      label: i18n.t('Set Auto Admin password'),
      icon: 'key',
      action: () => actions.onSetAutoAdminPassword(computer),
      isPresent:
        !isErased &&
        isActive &&
        isMdm &&
        isSupervised &&
        deviceFamily === 'Mac' &&
        regExpMapper['10.11+'].test(osVersion) &&
        get(computer, 'mdm_info.AutoSetupAdminAccounts.0.GUID', null) !== null,
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Log out user'),
      icon: 'logout',
      action: () => actions.onLogOutUser(computer),
      isPresent: !isErased && isMdm && isSharedIPad,
      isDisabled: !permissions.canManageDevices || !isActive,
    },
    {
      label: i18n.t('Lock device'),
      icon: 'lock',
      action: () =>
        deviceFamily === 'Mac'
          ? actions.onLockDevice(computer)
          : actions.onLockIosDevice(computer),
      isPresent:
        !isErased &&
        isMdm &&
        ['iPhone', 'iPad', 'iPod', 'Mac'].includes(deviceFamily),
      isDisabled:
        !permissions.canManageDevices ||
        (!isActive && deviceFamily !== 'AppleTV') ||
        isLostModeEnabled ||
        isLostModePending,
    },
    {
      label: i18n.t('Erase device'),
      icon: 'eraser',
      action: () => actions.onEraseDevice(computer),
      isPresent: !isErased && isMdm,
      isDisabled: !permissions.canManageDevices || !isActive,
    },
    {
      label: i18n.t('Renew MDM Profile'),
      icon: 'arrow-rotate-right',
      action: () => actions.onRenewMDM(computer),
      isPresent: !isErased && isMdm && flags['mdm-renew-profile'],
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Unlock user account'),
      icon: 'user',
      action: () => actions.onUnlockUser(computer),
      isPresent:
        !isErased &&
        isMdm &&
        deviceFamily === 'Mac' &&
        regExpMapper['10.13+'].test(osVersion),
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Delete user account'),
      icon: 'trash-can',
      action: () => actions.onDeleteUser(computer),
      isPresent:
        !isErased &&
        isMdm &&
        ((deviceFamily === 'Mac' && isSupervised) || isSharedIPad),
      isDisabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Re-install Agent'),
      icon: 'arrow-down-to-line',
      action: () => actions.onReinstallAgent(computer),
      isPresent: !isErased && isMdm && deviceFamily === 'Mac',
      isDisabled: !permissions.canManageDevices || !isActive,
    },
    {
      label: i18n.t('Enable Lost Mode'),
      icon: 'radar',
      action: () => actions.onEnableLostMode(computer),
      isPresent:
        isMdm &&
        (isLostModeUnset || isLostModeDisabled) &&
        isSupervised &&
        ['iPhone', 'iPad', 'iPod'].includes(deviceFamily),
      isDisabled:
        !permissions.canManageDevices || !isActive || isLostModePending,
    },
    {
      label: i18n.t('Cancel Lost Mode'),
      icon: 'radar',
      action: () => actions.onCancelLostMode(computer, lostModeCommandId),
      isPresent:
        isMdm &&
        isLostModePending &&
        lostModeCommandId &&
        isSupervised &&
        ['iPhone', 'iPad', 'iPod'].includes(deviceFamily),
      isDisabled: !permissions.canManageDevices || !isActive,
    },
    {
      label: i18n.t('Disable Lost Mode'),
      icon: 'radar',
      action: () => actions.onDisableLostMode(computer),
      isPresent:
        isMdm &&
        isLostModeEnabled &&
        ['iPhone', 'iPad', 'iPod'].includes(deviceFamily),
      isDisabled:
        !permissions.canManageDevices || !isActive || isLostModeDisablePending,
    },
    {
      label: i18n.t('Clear passcode'),
      icon: 'unlock',
      action: () => actions.onUnlockIosDevice(computer),
      isPresent:
        !isErased &&
        isActive &&
        isMdm &&
        ['iPhone', 'iPad', 'iPod'].includes(deviceFamily),
      isDisabled: !permissions.canManageDevices || !device?.has_unlock_token,
    },
    {
      label: i18n.t('Reset Microsoft registration'),
      icon: 'arrow-rotate-right',
      action: () =>
        actions.onShowResetMsDeviceRegistration(computer, integration?.uuid),
      isPresent:
        isResetMsRegistrationEnabled &&
        flags['SPLINT-920-reset-ms-device-registration-fe'],
    },
    {
      label: i18n.t('Delete device record'),
      icon: 'trash-can',
      action: () =>
        actions.onDeleteComputer(
          computer,
          isErased,
          isLocked,
          activationLockSupported,
          osVersion,
          deviceFamily,
          model,
        ),
      isPresent: true,
      isDisabled: !permissions.canManageDevices,
      isRed: permissions.canManageDevices,
    },
    {
      render: () => <Separator css={{ background: '$neutral20' }} />,
      isPresent:
        !isErased &&
        isMdm &&
        deviceFamily in versionTestForShutdownDevice &&
        regExpMapper[versionTestForShutdownDevice[deviceFamily]].test(
          osVersion,
        ) &&
        ((['iPhone', 'iPad', 'iPod', 'AppleTV'].includes(deviceFamily) &&
          isSupervised) ||
          deviceFamily === 'Mac'),
    },
    {
      label: i18n.t('Restart device'),
      icon: 'arrow-rotate-right',
      action: () => actions.onRestartDevice(computer),
      isPresent:
        !isErased &&
        isMdm &&
        deviceFamily in versionTestForShutdownDevice &&
        regExpMapper[versionTestForShutdownDevice[deviceFamily]].test(
          osVersion,
        ) &&
        ((['iPhone', 'iPad', 'iPod', 'AppleTV'].includes(deviceFamily) &&
          isSupervised) ||
          deviceFamily === 'Mac'),
      isDisabled: !permissions.canManageDevices || !isActive,
    },
    {
      label: i18n.t('Shutdown device'),
      icon: 'power-off',
      action: () => actions.onShutdownDevice(computer),
      isPresent:
        !isErased &&
        isMdm &&
        deviceFamily in versionTestForShutdownDevice &&
        regExpMapper[versionTestForShutdownDevice[deviceFamily]].test(
          osVersion,
        ) &&
        ((['iPhone', 'iPad', 'iPod'].includes(deviceFamily) && isSupervised) ||
          deviceFamily === 'Mac'),
      isDisabled: !permissions.canManageDevices || !isActive,
    },
  ];

  return (
    <DropdownMenu
      css={{
        zIndex: 99999999,
        '& > :has([data-orientation])': {
          paddingRight: 0,
          paddingLeft: 0,
        },
      }}
      options={menuOptions
        .filter((option) => option.isPresent)
        .map((option) => ({
          ...option,
          disabled: option.isDisabled,
          onClick: option.action,
          theme: option.isRed ? 'danger' : 'default',
        }))}
      withArrow={false}
      contentProps={{ align: 'end' }}
    >
      <Button
        compact
        icon={{ name: 'ellipsis' }}
        onClick={() => setMenuIsShowing(!menuIsShowing)}
        css={{ padding: '6px' }}
      />
    </DropdownMenu>
  );
};

DeviceActions.propTypes = {
  computer: PropTypes.object.isRequired,
};

export default DeviceActions;
