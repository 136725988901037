import type {
  GetByThreatsResponse,
  ThreatGroup,
} from '../../../../threat.types';

const decreaseNotQuarantinedCount = (
  response: { data: GetByThreatsResponse },
  threatId: string,
): { data: GetByThreatsResponse } => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.map((item: ThreatGroup) =>
      item.threat_id === threatId
        ? {
            ...item,
            not_quarantined_count: item.not_quarantined_count - 1,
            resolved_count: item.resolved_count + 1,
          }
        : item,
    ),
  },
});

export default decreaseNotQuarantinedCount;
