/* istanbul ignore */
import { updateSetting as update } from '@kandji-inc/bumblebee';
import ActivityTab from 'features/library-items/common/activity-tab';
import { populateSelectedDevices } from 'features/library-items/data-service/library-item/devices';
import config from 'features/library-items/library/library-item-configurations/items/profile';
import LibraryContext from 'features/library-items/routes/library.context';
import { useCallback, useContext, useEffect } from 'react';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import initialState from './initial-state';
//import { MessageConfig } from './sections';

import { lsmService } from './service/lsm-service';
import { transformFromApi, transformToApi } from './service/lsm-transformers';
import useService from './service/use-lsm-service';

const LockScreenMessage = (props) => {
  // const { model, setModel, pageState } = props;
  // const [validationDep, triggerValidation] = useUniqValue();
  const { itemConfig } = useContext(LibraryContext);

  // const updateLockScreenMessage = useCallback(
  //   update('LockScreenFootnote', setModel),
  //   [],
  // );

  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name}`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      //triggerValidation={triggerValidation}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      service={lsmService}
      defaultIcon={itemConfig.icon}
      supportsDuplication
      supportsRules
      supportsInstallOn
      blueprintConflicts={blueprintConflicts}
      {...pageProps}
    ></LibraryItemPage>
  );
};

export default LockScreenMessage;
