/* istanbul ignore file */
import { i18n } from 'src/i18n';

export const userTypes = {
  CompanyAdmin: 'CompanyAdmin',
  CompanyUser: 'CompanyUser',
  SuperAdmin: 'SuperAdmin',

  super: 'super',
  company: 'company',
};

export const UserRoles = {
  admin: 'admin',
  standard: 'standard',
  helpdesk: 'helpdesk',
  auditor: 'auditor',
};

export const UserRoleLabelsMap = i18n.createMap({
  [UserRoles.admin]: () => i18n.t('Admin'),
  [UserRoles.standard]: () => i18n.t('Standard'),
  [UserRoles.helpdesk]: () => i18n.t('Help Desk'),
  [UserRoles.auditor]: () => i18n.t('Auditor'),
});

export const UserRoleOptions = Object.entries(UserRoles).map(([k, v]) => ({
  value: k,
  get label() {
    return UserRoleLabelsMap(v);
  },
}));

export const ParameterType = {
  disable_with_restrictions: 'disable_with_restrictions',
  multi_array: 'multi_array',
  application_blacklisting: 'application_blacklisting',
  set_computer_name: 'set_computer_name',
  secure_wifi_settings: 'secure_wifi_settings',
};

export const parameterID = {
  create_user_accounts: 'bb40515e-4b28-48fc-9596-59c8db14a7a3',
  blacklist: '1bf63aac-9033-49b4-93f1-0ac857afb8a8',
  retention_for_security_auditing: 'cbb3b9c5-2db4-476a-9faa-0c61b7354ec1',
  security_auditing_maximum_log_size: 'c4215248-df85-4af8-ada1-5afc305a7447',
  manage_screen_sharing: '428d99a1-5ab0-4198-b248-7603d1c2e258',
  manage_remote_apple_events: 'fb63a562-062d-4679-9e16-3dd2453e726d',
  custom_policy_banner: '776f1c86-54ea-4876-a226-a0a07040fec0',
  set_computer_name: '1d11235c-707f-4f26-b6ca-a27f20f3ee83',
  secure_wifi_settings: 'ec0a818c-42ef-4755-a423-72ed2580838b',
};

export const selectOptionsMapping = {
  '0720f00c-393a-4a66-b676-efa82307ebd4': {
    1: 'Deny without prompting',
    0: 'Prompt for each website',
  },
  '3d81acd3-8d3d-4941-a88e-6b8b9147cc6a': {
    0: 'Start Screen Saver',
    1: 'Sleep Display',
    'wvous-tl-corner': 'Top-left',
    'wvous-tr-corner': 'Top-right',
    'wvous-bl-corner': 'Bottom-left',
    'wvous-br-corner': 'Bottom-right',
  },
  'ec0a818c-42ef-4755-a423-72ed2580838b': {
    computer_to_computer: 'Create computer-to-computer networks',
    network_change: 'Change networks',
    power_toggle: 'Turn Wi-Fi on or off',
  },
  '51a763db-716c-4bfa-b721-2f303d203b3b': {
    1: 'Enable Location Services',
    0: 'Disable Location Services',
  },
  '4aa511a0-be7d-4819-9c1d-0c781d3bde97': {
    1: 'Enable WiFi',
    0: 'Disable WiFi',
  },
  '8c6b6864-3faa-47f1-b599-e13bb04682ed': {
    1: 'Deny without prompting',
    0: 'Prompt for each website',
  },
  '93335d6f-3007-4648-a3e0-7e081bdccbfd': {
    0: 'Contacts Only',
    1: 'Everyone',
    2: 'No One',
  },
  '07cac17b-33b0-4af3-8302-3e5ded562b45': {
    1: 'Enable',
    0: 'Disable',
  },
  '5d78eebc-8552-4f9a-8236-44b9e7a4bff2': {
    0: 'Disable only if no device paired',
    1: 'Disabled',
    2: 'Enabled',
  },
  '08323d29-7296-4fc1-8a74-295b3ac2b081': {
    0: 'Hide',
    1: 'Show',
  },
  '428d99a1-5ab0-4198-b248-7603d1c2e258': {
    false: 'Disable Screen Sharing',
    true: 'Restrict to Users and Groups',
  },
  'fb63a562-062d-4679-9e16-3dd2453e726d': {
    false: 'Disable Remote Apple Events',
    true: 'Restrict to Users and Groups',
  },
  '1bf63aac-9033-49b4-93f1-0ac857afb8a8': {
    by_path: 'By Path',
    by_process: 'By Process',
    by_bundle_id: 'By Bundle ID',
    by_developer_id: 'By Developer ID',
    message_customization: 'Message Customization',

    full_path_to_app: 'Full path to app',
    process_name: 'Process name',
    bundle_identifier: 'Bundle identifier',
    developer_id: 'Developer ID',
    message: 'Message to display when an app is blocked',
    button_title: 'Custom button title',
    url: 'URL to open with button is clicked',

    exact: 'Exact',
    contains: 'Contains',
    regex: 'Regex',
  },
  '91e86fb9-1c25-45b0-b1ed-064f8a478409': {
    // PREFIX
    none: 'None',
    '+': 'Record successful events (+)',
    '-': 'Record failed events (-)',
    '^': 'Record neither successful or failed events (^)',
    '^+': 'Do not record successful events (^+)',
    '^-': 'Do not record failed events (^-)',

    // FLAGS
    fr: 'File read (fr)',
    fw: 'File write (fw)',
    fa: 'File attribute access (fa)',
    fm: 'File attribute modify (fm)',
    fc: 'File create (fc)',
    fd: 'File delete (fd)',
    cl: 'File close (cl)',
    pc: 'Process (pc)',
    nt: 'Network (nt)',
    ip: 'IPC operations (ip)',
    na: 'Non attributable (na)',
    ad: 'Administrative (ad)',
    lo: 'Login / Logout (lo)',
    aa: 'Authentication and authorization (aa)',
    ap: 'Application (ap)',
    io: 'ioctl (io)',
    ex: 'Program execution (ex)',
    or: 'Miscellaneous (or)',
    all: 'All flags set (all)',
  },
  [parameterID.set_computer_name]: {
    model_name: 'Model Name',
    kandji_user_name: 'Assigned User',
    asset_tag: 'Asset Tag',
    custom_text: 'Custom Text',
    blueprint_name: 'Blueprint Name',
    company_name: 'Company Name',
    serial_number: 'Serial Number',
    primary_local_user_name: 'Primary Local User Name',
  },
};

export const times = new Array(48).fill(undefined).map((_, i) => {
  if (i === 0) {
    return {
      value: '00:00',
      label: '12 midnight',
    };
  }
  if (i === 1) {
    return {
      value: '00:30',
      label: '12:30 a.m.',
    };
  }
  if (i === 24) {
    return {
      value: '12:00',
      label: '12 noon',
    };
  }
  if (i === 25) {
    return {
      value: '12:30',
      label: '12:30 p.m.',
    };
  }
  return {
    value: `${(i - (i % 2)) / 2}:${i % 2 ? '30' : '00'}`,
    label: `${((i % 24) - (i % 2)) / 2}:${i % 2 ? '30' : '00'} ${
      i > 24 ? 'p.m.' : 'a.m.'
    }`,
  };
});

export const AUTO_APP_DEFAULT_TIMEZONE = () => ({
  value: 'Device/Local',
  label: i18n.t("Device's local time zone"),
});

export const timezones = [
  {
    value: null,
    label: 'Choose a timezone',
  },
  {
    value: 'Pacific/Niue',
    label: '(GMT-11:00) Niue',
  },
  {
    value: 'Pacific/Pago_Pago',
    label: '(GMT-11:00) Pago Pago',
  },
  {
    value: 'Pacific/Honolulu',
    label: '(GMT-10:00) Hawaii Time',
  },
  {
    value: 'Pacific/Rarotonga',
    label: '(GMT-10:00) Rarotonga',
  },
  {
    value: 'Pacific/Tahiti',
    label: '(GMT-10:00) Tahiti',
  },
  {
    value: 'Pacific/Marquesas',
    label: '(GMT-09:30) Marquesas',
  },
  {
    value: 'America/Anchorage',
    label: '(GMT-09:00) Alaska Time',
  },
  {
    value: 'Pacific/Gambier',
    label: '(GMT-09:00) Gambier',
  },
  {
    value: 'America/Los_Angeles',
    label: '(GMT-08:00) Pacific Time - Los Angeles',
  },
  {
    value: 'America/Tijuana',
    label: '(GMT-08:00) Pacific Time - Tijuana',
  },
  {
    value: 'America/Vancouver',
    label: '(GMT-08:00) Pacific Time - Vancouver',
  },
  {
    value: 'America/Whitehorse',
    label: '(GMT-08:00) Pacific Time - Whitehorse',
  },
  {
    value: 'Pacific/Pitcairn',
    label: '(GMT-08:00) Pitcairn',
  },
  {
    value: 'America/Denver',
    label: '(GMT-07:00) Mountain Time',
  },
  {
    value: 'America/Phoenix',
    label: '(GMT-07:00) Mountain Time - Arizona',
  },
  {
    value: 'America/Mazatlan',
    label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  },
  {
    value: 'America/Dawson_Creek',
    label: '(GMT-07:00) Mountain Time - Dawson Creek',
  },
  {
    value: 'America/Edmonton',
    label: '(GMT-07:00) Mountain Time - Edmonton',
  },
  {
    value: 'America/Hermosillo',
    label: '(GMT-07:00) Mountain Time - Hermosillo',
  },
  {
    value: 'America/Yellowknife',
    label: '(GMT-07:00) Mountain Time - Yellowknife',
  },
  {
    value: 'America/Belize',
    label: '(GMT-06:00) Belize',
  },
  {
    value: 'America/Chicago',
    label: '(GMT-06:00) Central Time',
  },
  {
    value: 'America/Mexico_City',
    label: '(GMT-06:00) Central Time - Mexico City',
  },
  {
    value: 'America/Regina',
    label: '(GMT-06:00) Central Time - Regina',
  },
  {
    value: 'America/Tegucigalpa',
    label: '(GMT-06:00) Central Time - Tegucigalpa',
  },
  {
    value: 'America/Winnipeg',
    label: '(GMT-06:00) Central Time - Winnipeg',
  },
  {
    value: 'America/Costa_Rica',
    label: '(GMT-06:00) Costa Rica',
  },
  {
    value: 'America/El_Salvador',
    label: '(GMT-06:00) El Salvador',
  },
  {
    value: 'Pacific/Galapagos',
    label: '(GMT-06:00) Galapagos',
  },
  {
    value: 'America/Guatemala',
    label: '(GMT-06:00) Guatemala',
  },
  {
    value: 'America/Managua',
    label: '(GMT-06:00) Managua',
  },
  {
    value: 'America/Cancun',
    label: '(GMT-05:00) America Cancun',
  },
  {
    value: 'America/Bogota',
    label: '(GMT-05:00) Bogota',
  },
  {
    value: 'Pacific/Easter',
    label: '(GMT-05:00) Easter Island',
  },
  {
    value: 'America/New_York',
    label: '(GMT-05:00) Eastern Time',
  },
  {
    value: 'America/Iqaluit',
    label: '(GMT-05:00) Eastern Time - Iqaluit',
  },
  {
    value: 'America/Toronto',
    label: '(GMT-05:00) Eastern Time - Toronto',
  },
  {
    value: 'America/Guayaquil',
    label: '(GMT-05:00) Guayaquil',
  },
  {
    value: 'America/Havana',
    label: '(GMT-05:00) Havana',
  },
  {
    value: 'America/Jamaica',
    label: '(GMT-05:00) Jamaica',
  },
  {
    value: 'America/Lima',
    label: '(GMT-05:00) Lima',
  },
  {
    value: 'America/Nassau',
    label: '(GMT-05:00) Nassau',
  },
  {
    value: 'America/Panama',
    label: '(GMT-05:00) Panama',
  },
  {
    value: 'America/Port-au-Prince',
    label: '(GMT-05:00) Port-au-Prince',
  },
  {
    value: 'America/Rio_Branco',
    label: '(GMT-05:00) Rio Branco',
  },
  {
    value: 'America/Halifax',
    label: '(GMT-04:00) Atlantic Time - Halifax',
  },
  {
    value: 'America/Barbados',
    label: '(GMT-04:00) Barbados',
  },
  {
    value: 'Atlantic/Bermuda',
    label: '(GMT-04:00) Bermuda',
  },
  {
    value: 'America/Boa_Vista',
    label: '(GMT-04:00) Boa Vista',
  },
  {
    value: 'America/Caracas',
    label: '(GMT-04:00) Caracas',
  },
  {
    value: 'America/Curacao',
    label: '(GMT-04:00) Curacao',
  },
  {
    value: 'America/Grand_Turk',
    label: '(GMT-04:00) Grand Turk',
  },
  {
    value: 'America/Guyana',
    label: '(GMT-04:00) Guyana',
  },
  {
    value: 'America/La_Paz',
    label: '(GMT-04:00) La Paz',
  },
  {
    value: 'America/Manaus',
    label: '(GMT-04:00) Manaus',
  },
  {
    value: 'America/Martinique',
    label: '(GMT-04:00) Martinique',
  },
  {
    value: 'America/Port_of_Spain',
    label: '(GMT-04:00) Port of Spain',
  },
  {
    value: 'America/Porto_Velho',
    label: '(GMT-04:00) Porto Velho',
  },
  {
    value: 'America/Puerto_Rico',
    label: '(GMT-04:00) Puerto Rico',
  },
  {
    value: 'America/Santo_Domingo',
    label: '(GMT-04:00) Santo Domingo',
  },
  {
    value: 'America/Thule',
    label: '(GMT-04:00) Thule',
  },
  {
    value: 'America/St_Johns',
    label: '(GMT-03:30) Newfoundland Time - St. Johns',
  },
  {
    value: 'America/Araguaina',
    label: '(GMT-03:00) Araguaina',
  },
  {
    value: 'America/Asuncion',
    label: '(GMT-03:00) Asuncion',
  },
  {
    value: 'America/Belem',
    label: '(GMT-03:00) Belem',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: '(GMT-03:00) Buenos Aires',
  },
  {
    value: 'America/Campo_Grande',
    label: '(GMT-03:00) Campo Grande',
  },
  {
    value: 'America/Cayenne',
    label: '(GMT-03:00) Cayenne',
  },
  {
    value: 'America/Cuiaba',
    label: '(GMT-03:00) Cuiaba',
  },
  {
    value: 'America/Fortaleza',
    label: '(GMT-03:00) Fortaleza',
  },
  {
    value: 'America/Godthab',
    label: '(GMT-03:00) Godthab',
  },
  {
    value: 'America/Maceio',
    label: '(GMT-03:00) Maceio',
  },
  {
    value: 'America/Miquelon',
    label: '(GMT-03:00) Miquelon',
  },
  {
    value: 'America/Montevideo',
    label: '(GMT-03:00) Montevideo',
  },
  {
    value: 'Antarctica/Palmer',
    label: '(GMT-03:00) Palmer',
  },
  {
    value: 'America/Paramaribo',
    label: '(GMT-03:00) Paramaribo',
  },
  {
    value: 'America/Punta_Arenas',
    label: '(GMT-03:00) Punta Arenas',
  },
  {
    value: 'America/Recife',
    label: '(GMT-03:00) Recife',
  },
  {
    value: 'Antarctica/Rothera',
    label: '(GMT-03:00) Rothera',
  },
  {
    value: 'America/Bahia',
    label: '(GMT-03:00) Salvador',
  },
  {
    value: 'America/Santiago',
    label: '(GMT-03:00) Santiago',
  },
  {
    value: 'Atlantic/Stanley',
    label: '(GMT-03:00) Stanley',
  },
  {
    value: 'America/Noronha',
    label: '(GMT-02:00) Noronha',
  },
  {
    value: 'America/Sao_Paulo',
    label: '(GMT-02:00) Sao Paulo',
  },
  {
    value: 'Atlantic/South_Georgia',
    label: '(GMT-02:00) South Georgia',
  },
  {
    value: 'Atlantic/Azores',
    label: '(GMT-01:00) Azores',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: '(GMT-01:00) Cape Verde',
  },
  {
    value: 'America/Scoresbysund',
    label: '(GMT-01:00) Scoresbysund',
  },
  {
    value: 'Africa/Abidjan',
    label: '(GMT+00:00) Abidjan',
  },
  {
    value: 'Africa/Accra',
    label: '(GMT+00:00) Accra',
  },
  {
    value: 'Africa/Bissau',
    label: '(GMT+00:00) Bissau',
  },
  {
    value: 'Atlantic/Canary',
    label: '(GMT+00:00) Canary Islands',
  },
  {
    value: 'Africa/Casablanca',
    label: '(GMT+00:00) Casablanca',
  },
  {
    value: 'America/Danmarkshavn',
    label: '(GMT+00:00) Danmarkshavn',
  },
  {
    value: 'Europe/Dublin',
    label: '(GMT+00:00) Dublin',
  },
  {
    value: 'Africa/El_Aaiun',
    label: '(GMT+00:00) El Aaiun',
  },
  {
    value: 'Atlantic/Faroe',
    label: '(GMT+00:00) Faeroe',
  },
  {
    value: 'Etc/GMT',
    label: '(GMT+00:00) GMT (no daylight saving)',
  },
  {
    value: 'Europe/Lisbon',
    label: '(GMT+00:00) Lisbon',
  },
  {
    value: 'Europe/London',
    label: '(GMT+00:00) London',
  },
  {
    value: 'Africa/Monrovia',
    label: '(GMT+00:00) Monrovia',
  },
  {
    value: 'Atlantic/Reykjavik',
    label: '(GMT+00:00) Reykjavik',
  },
  {
    value: 'UTC',
    label: 'UTC',
  },
  {
    value: 'Africa/Algiers',
    label: '(GMT+01:00) Algiers',
  },
  {
    value: 'Europe/Amsterdam',
    label: '(GMT+01:00) Amsterdam',
  },
  {
    value: 'Europe/Andorra',
    label: '(GMT+01:00) Andorra',
  },
  {
    value: 'Europe/Berlin',
    label: '(GMT+01:00) Berlin',
  },
  {
    value: 'Europe/Brussels',
    label: '(GMT+01:00) Brussels',
  },
  {
    value: 'Europe/Budapest',
    label: '(GMT+01:00) Budapest',
  },
  {
    value: 'Europe/Belgrade',
    label: '(GMT+01:00) Central European Time - Belgrade',
  },
  {
    value: 'Europe/Prague',
    label: '(GMT+01:00) Central European Time - Prague',
  },
  {
    value: 'Africa/Ceuta',
    label: '(GMT+01:00) Ceuta',
  },
  {
    value: 'Europe/Copenhagen',
    label: '(GMT+01:00) Copenhagen',
  },
  {
    value: 'Europe/Gibraltar',
    label: '(GMT+01:00) Gibraltar',
  },
  {
    value: 'Africa/Lagos',
    label: '(GMT+01:00) Lagos',
  },
  {
    value: 'Europe/Luxembourg',
    label: '(GMT+01:00) Luxembourg',
  },
  {
    value: 'Europe/Madrid',
    label: '(GMT+01:00) Madrid',
  },
  {
    value: 'Europe/Malta',
    label: '(GMT+01:00) Malta',
  },
  {
    value: 'Europe/Monaco',
    label: '(GMT+01:00) Monaco',
  },
  {
    value: 'Africa/Ndjamena',
    label: '(GMT+01:00) Ndjamena',
  },
  {
    value: 'Europe/Oslo',
    label: '(GMT+01:00) Oslo',
  },
  {
    value: 'Europe/Paris',
    label: '(GMT+01:00) Paris',
  },
  {
    value: 'Europe/Rome',
    label: '(GMT+01:00) Rome',
  },
  {
    value: 'Europe/Stockholm',
    label: '(GMT+01:00) Stockholm',
  },
  {
    value: 'Europe/Tirane',
    label: '(GMT+01:00) Tirane',
  },
  {
    value: 'Africa/Tunis',
    label: '(GMT+01:00) Tunis',
  },
  {
    value: 'Europe/Vienna',
    label: '(GMT+01:00) Vienna',
  },
  {
    value: 'Europe/Warsaw',
    label: '(GMT+01:00) Warsaw',
  },
  {
    value: 'Europe/Zurich',
    label: '(GMT+01:00) Zurich',
  },
  {
    value: 'Asia/Amman',
    label: '(GMT+02:00) Amman',
  },
  {
    value: 'Europe/Athens',
    label: '(GMT+02:00) Athens',
  },
  {
    value: 'Asia/Beirut',
    label: '(GMT+02:00) Beirut',
  },
  {
    value: 'Europe/Bucharest',
    label: '(GMT+02:00) Bucharest',
  },
  {
    value: 'Africa/Cairo',
    label: '(GMT+02:00) Cairo',
  },
  {
    value: 'Europe/Chisinau',
    label: '(GMT+02:00) Chisinau',
  },
  {
    value: 'Asia/Damascus',
    label: '(GMT+02:00) Damascus',
  },
  {
    value: 'Asia/Gaza',
    label: '(GMT+02:00) Gaza',
  },
  {
    value: 'Europe/Helsinki',
    label: '(GMT+02:00) Helsinki',
  },
  {
    value: 'Asia/Jerusalem',
    label: '(GMT+02:00) Jerusalem',
  },
  {
    value: 'Africa/Johannesburg',
    label: '(GMT+02:00) Johannesburg',
  },
  {
    value: 'Africa/Khartoum',
    label: '(GMT+02:00) Khartoum',
  },
  {
    value: 'Europe/Kiev',
    label: '(GMT+02:00) Kyiv',
  },
  {
    value: 'Africa/Maputo',
    label: '(GMT+02:00) Maputo',
  },
  {
    value: 'Europe/Kaliningrad',
    label: '(GMT+02:00) Moscow-01 - Kaliningrad',
  },
  {
    value: 'Asia/Nicosia',
    label: '(GMT+02:00) Nicosia',
  },
  {
    value: 'Europe/Riga',
    label: '(GMT+02:00) Riga',
  },
  {
    value: 'Europe/Sofia',
    label: '(GMT+02:00) Sofia',
  },
  {
    value: 'Europe/Tallinn',
    label: '(GMT+02:00) Tallinn',
  },
  {
    value: 'Africa/Tripoli',
    label: '(GMT+02:00) Tripoli',
  },
  {
    value: 'Europe/Vilnius',
    label: '(GMT+02:00) Vilnius',
  },
  {
    value: 'Africa/Windhoek',
    label: '(GMT+02:00) Windhoek',
  },
  {
    value: 'Asia/Baghdad',
    label: '(GMT+03:00) Baghdad',
  },
  {
    value: 'Europe/Istanbul',
    label: '(GMT+03:00) Istanbul',
  },
  {
    value: 'Europe/Minsk',
    label: '(GMT+03:00) Minsk',
  },
  {
    value: 'Europe/Moscow',
    label: '(GMT+03:00) Moscow+00 - Moscow',
  },
  {
    value: 'Africa/Nairobi',
    label: '(GMT+03:00) Nairobi',
  },
  {
    value: 'Asia/Qatar',
    label: '(GMT+03:00) Qatar',
  },
  {
    value: 'Asia/Riyadh',
    label: '(GMT+03:00) Riyadh',
  },
  {
    value: 'Antarctica/Syowa',
    label: '(GMT+03:00) Syowa',
  },
  {
    value: 'Asia/Tehran',
    label: '(GMT+03:30) Tehran',
  },
  {
    value: 'Asia/Baku',
    label: '(GMT+04:00) Baku',
  },
  {
    value: 'Asia/Dubai',
    label: '(GMT+04:00) Dubai',
  },
  {
    value: 'Indian/Mahe',
    label: '(GMT+04:00) Mahe',
  },
  {
    value: 'Indian/Mauritius',
    label: '(GMT+04:00) Mauritius',
  },
  {
    value: 'Europe/Samara',
    label: '(GMT+04:00) Moscow+01 - Samara',
  },
  {
    value: 'Indian/Reunion',
    label: '(GMT+04:00) Reunion',
  },
  {
    value: 'Asia/Tbilisi',
    label: '(GMT+04:00) Tbilisi',
  },
  {
    value: 'Asia/Yerevan',
    label: '(GMT+04:00) Yerevan',
  },
  {
    value: 'Asia/Kabul',
    label: '(GMT+04:30) Kabul',
  },
  {
    value: 'Asia/Aqtau',
    label: '(GMT+05:00) Aqtau',
  },
  {
    value: 'Asia/Aqtobe',
    label: '(GMT+05:00) Aqtobe',
  },
  {
    value: 'Asia/Ashgabat',
    label: '(GMT+05:00) Ashgabat',
  },
  {
    value: 'Asia/Dushanbe',
    label: '(GMT+05:00) Dushanbe',
  },
  {
    value: 'Asia/Karachi',
    label: '(GMT+05:00) Karachi',
  },
  {
    value: 'Indian/Kerguelen',
    label: '(GMT+05:00) Kerguelen',
  },
  {
    value: 'Indian/Maldives',
    label: '(GMT+05:00) Maldives',
  },
  {
    value: 'Antarctica/Mawson',
    label: '(GMT+05:00) Mawson',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: '(GMT+05:00) Moscow+02 - Yekaterinburg',
  },
  {
    value: 'Asia/Tashkent',
    label: '(GMT+05:00) Tashkent',
  },
  {
    value: 'Asia/Colombo',
    label: '(GMT+05:30) Colombo',
  },
  {
    value: 'Asia/Calcutta',
    label: '(GMT+05:30) India Standard Time',
  },
  {
    value: 'Asia/Kathmandu',
    label: '(GMT+05:45) Kathmandu',
  },
  {
    value: 'Asia/Almaty',
    label: '(GMT+06:00) Almaty',
  },
  {
    value: 'Asia/Bishkek',
    label: '(GMT+06:00) Bishkek',
  },
  {
    value: 'Indian/Chagos',
    label: '(GMT+06:00) Chagos',
  },
  {
    value: 'Asia/Dhaka',
    label: '(GMT+06:00) Dhaka',
  },
  {
    value: 'Asia/Omsk',
    label: '(GMT+06:00) Moscow+03 - Omsk',
  },
  {
    value: 'Asia/Thimphu',
    label: '(GMT+06:00) Thimphu',
  },
  {
    value: 'Antarctica/Vostok',
    label: '(GMT+06:00) Vostok',
  },
  {
    value: 'Indian/Cocos',
    label: '(GMT+06:30) Cocos',
  },
  {
    value: 'Asia/Yangon',
    label: '(GMT+06:30) Rangoon',
  },
  {
    value: 'Asia/Bangkok',
    label: '(GMT+07:00) Bangkok',
  },
  {
    value: 'Indian/Christmas',
    label: '(GMT+07:00) Christmas',
  },
  {
    value: 'Antarctica/Davis',
    label: '(GMT+07:00) Davis',
  },
  {
    value: 'Asia/Saigon',
    label: '(GMT+07:00) Hanoi',
  },
  {
    value: 'Asia/Hovd',
    label: '(GMT+07:00) Hovd',
  },
  {
    value: 'Asia/Jakarta',
    label: '(GMT+07:00) Jakarta',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: '(GMT+07:00) Moscow+04 - Krasnoyarsk',
  },
  {
    value: 'Asia/Brunei',
    label: '(GMT+08:00) Brunei',
  },
  {
    value: 'Asia/Shanghai',
    label: '(GMT+08:00) China Time - Beijing',
  },
  {
    value: 'Asia/Choibalsan',
    label: '(GMT+08:00) Choibalsan',
  },
  {
    value: 'Asia/Hong_Kong',
    label: '(GMT+08:00) Hong Kong',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: '(GMT+08:00) Kuala Lumpur',
  },
  {
    value: 'Asia/Macau',
    label: '(GMT+08:00) Macau',
  },
  {
    value: 'Asia/Makassar',
    label: '(GMT+08:00) Makassar',
  },
  {
    value: 'Asia/Manila',
    label: '(GMT+08:00) Manila',
  },
  {
    value: 'Asia/Irkutsk',
    label: '(GMT+08:00) Moscow+05 - Irkutsk',
  },
  {
    value: 'Asia/Singapore',
    label: '(GMT+08:00) Singapore',
  },
  {
    value: 'Asia/Taipei',
    label: '(GMT+08:00) Taipei',
  },
  {
    value: 'Asia/Ulaanbaatar',
    label: '(GMT+08:00) Ulaanbaatar',
  },
  {
    value: 'Australia/Perth',
    label: '(GMT+08:00) Western Time - Perth',
  },
  {
    value: 'Asia/Pyongyang',
    label: '(GMT+08:30) Pyongyang',
  },
  {
    value: 'Asia/Dili',
    label: '(GMT+09:00) Dili',
  },
  {
    value: 'Asia/Jayapura',
    label: '(GMT+09:00) Jayapura',
  },
  {
    value: 'Asia/Yakutsk',
    label: '(GMT+09:00) Moscow+06 - Yakutsk',
  },
  {
    value: 'Pacific/Palau',
    label: '(GMT+09:00) Palau',
  },
  {
    value: 'Asia/Seoul',
    label: '(GMT+09:00) Seoul',
  },
  {
    value: 'Asia/Tokyo',
    label: '(GMT+09:00) Tokyo',
  },
  {
    value: 'Australia/Darwin',
    label: '(GMT+09:30) Central Time - Darwin',
  },
  {
    value: 'Antarctica/DumontDUrville',
    label: "(GMT+10:00) Dumont D'Urville",
  },
  {
    value: 'Australia/Brisbane',
    label: '(GMT+10:00) Eastern Time - Brisbane',
  },
  {
    value: 'Pacific/Guam',
    label: '(GMT+10:00) Guam',
  },
  {
    value: 'Asia/Vladivostok',
    label: '(GMT+10:00) Moscow+07 - Vladivostok',
  },
  {
    value: 'Pacific/Port_Moresby',
    label: '(GMT+10:00) Port Moresby',
  },
  {
    value: 'Pacific/Chuuk',
    label: '(GMT+10:00) Truk',
  },
  {
    value: 'Australia/Adelaide',
    label: '(GMT+10:30) Central Time - Adelaide',
  },
  {
    value: 'Antarctica/Casey',
    label: '(GMT+11:00) Casey',
  },
  {
    value: 'Australia/Hobart',
    label: '(GMT+11:00) Eastern Time - Hobart',
  },
  {
    value: 'Australia/Sydney',
    label: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
  },
  {
    value: 'Pacific/Efate',
    label: '(GMT+11:00) Efate',
  },
  {
    value: 'Pacific/Guadalcanal',
    label: '(GMT+11:00) Guadalcanal',
  },
  {
    value: 'Pacific/Kosrae',
    label: '(GMT+11:00) Kosrae',
  },
  {
    value: 'Asia/Magadan',
    label: '(GMT+11:00) Moscow+08 - Magadan',
  },
  {
    value: 'Pacific/Norfolk',
    label: '(GMT+11:00) Norfolk',
  },
  {
    value: 'Pacific/Noumea',
    label: '(GMT+11:00) Noumea',
  },
  {
    value: 'Pacific/Pohnpei',
    label: '(GMT+11:00) Ponape',
  },
  {
    value: 'Pacific/Funafuti',
    label: '(GMT+12:00) Funafuti',
  },
  {
    value: 'Pacific/Kwajalein',
    label: '(GMT+12:00) Kwajalein',
  },
  {
    value: 'Pacific/Majuro',
    label: '(GMT+12:00) Majuro',
  },
  {
    value: 'Asia/Kamchatka',
    label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  },
  {
    value: 'Pacific/Nauru',
    label: '(GMT+12:00) Nauru',
  },
  {
    value: 'Pacific/Tarawa',
    label: '(GMT+12:00) Tarawa',
  },
  {
    value: 'Pacific/Wake',
    label: '(GMT+12:00) Wake',
  },
  {
    value: 'Pacific/Wallis',
    label: '(GMT+12:00) Wallis',
  },
  {
    value: 'Pacific/Auckland',
    label: '(GMT+13:00) Auckland',
  },
  {
    value: 'Pacific/Enderbury',
    label: '(GMT+13:00) Enderbury',
  },
  {
    value: 'Pacific/Fakaofo',
    label: '(GMT+13:00) Fakaofo',
  },
  {
    value: 'Pacific/Fiji',
    label: '(GMT+13:00) Fiji',
  },
  {
    value: 'Pacific/Tongatapu',
    label: '(GMT+13:00) Tongatapu',
  },
  {
    value: 'Pacific/Apia',
    label: '(GMT+14:00) Apia',
  },
  {
    value: 'Pacific/Kiritimati',
    label: '(GMT+14:00) Kiritimati',
  },
];

export const tabsNames = {
  vulnerability: 'Vulnerabilities',
  threat: 'Threats',
  companies: 'Customers',
  compliance: 'Compliance',
  computers: 'Computers',
  devices: 'Devices',
  users: 'Access',
  usersAlt: 'Users', // for G-Suite
  usersAll: 'All Users', // for G-Suite
  parameters: 'Parameters',
  parameterPage: 'Items',
  general: 'General',
  api: 'API',
  settings: 'Settings',
  status: 'Status',
  activityStream: 'Activity Stream',
  techSpecs: 'Details',
  results: 'Status Changes',
  notes: 'Notes',
  library: 'Library',
  activity: 'Activity',
  alerts: 'Alerts',
  blueprints: 'Blueprints',
  vulnerabilities: 'Vulnerabilities',
  blueprintsSettings: 'Blueprint Settings',
  blueprintTemplates: 'Blueprint Templates',
  active: 'Alerts',
  activeMuted: 'Muted Alerts',
  old: 'Cleared Alerts',
  companySettings: 'Customer Settings',
  companySettingsAlt: 'Settings',
  adminPortal: 'Admin Portal',
  agentErrors: 'Agent Errors',
  errorsTab: 'Errors',
  diagnosticsTab: 'Diagnostics',
  parameterErrors: 'Missing Parameters',
  remediationsErrors: 'Remediation Alerts',
  integrations: 'Integrations',
  categories: 'Categories',
  subcategories: 'Subcategories',
  applicationInventoryTab: 'Applications',
  applicationInventoryTabForIOS: 'Apps',
  appBlacklistRulesTab: 'Blocked Apps',
  withDevices: 'Device Users',
  withoutDevices: 'Users Without Devices',
  archived: 'Archived Users',
  parameterTags: 'Parameter Tags',
  help: 'Help',
  addDevices: 'Add Devices',
  deviceEnrollment: 'Enrollment',
  featureRequest: 'Feature Request',
  selfService: 'Self Service',
  threats: 'Threats',
  lostMode: 'Lost Mode',
  resources: 'Resources',
};

export const defaultFilters = {
  status: ['ALL'],
  period: '7',
  parameter: ['ALL'],
  blueprint: ['ALL'],
  computer: ['ALL'],
  connectivity: 'ALL',
  displayType: 'STATUS_CHANGE',
  sizePerPage: 25,
  sortName: 'started_at', // legacy
  sortOrder: 'desc', // legacy
  ordering: 'started_at',
  page: 1,
  search: '',
};

export const defaultActivityFilters = {
  type: ['ALL'],
  period: 'ALL',
  ordering: '-created_at',
};

export const defaultAlertsFilters = {
  status: 'ERROR,WARNING,MUTE',
};

export const filters = [
  {
    value: 'blueprint',
    label: 'Blueprint',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
    ],
  },
  { value: 'status', label: 'Last Status', operators: ['equals', 'notEqual'] },
  {
    value: 'computerName',
    label: 'Device Name',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
      'isBlank',
    ],
  },
  {
    value: 'model',
    label: 'Model',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
      'isBlank',
    ],
  },
  {
    value: 'lastCheckIn',
    label: 'Last Check-In',
    operators: ['exact', 'after', 'before', 'between'],
  },
  {
    value: 'lastEnrollment',
    label: 'Last Enrollment',
    operators: ['exact', 'after', 'before', 'between'],
  },
  {
    value: 'firstEnrollment',
    label: 'First Enrollment',
    operators: ['exact', 'after', 'before', 'between'],
  },
  {
    value: 'serial',
    label: 'Serial',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
    ],
  },
  {
    value: 'os',
    label: 'OS',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
      'isBlank',
    ],
  },
  {
    value: 'assetUser',
    label: 'User',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
      'isBlank',
    ],
  },
  {
    value: 'assetTag',
    label: 'Asset Tag',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
      'isBlank',
    ],
  },
  {
    value: 'agentVersion',
    label: 'Agent Version',
    operators: [
      'equals',
      'notEqual',
      'startsWith',
      'doesNotContain',
      'contains',
      'isCurrent',
      'isNotCurrent',
    ],
  },
];

export const operators = [
  { value: 'equals', label: 'includes any', type: 'select' },
  { value: 'greaterThan', label: 'greater than', type: 'input' },
  { value: 'lessThan', label: 'less than', type: 'input' },
  { value: 'notEqual', label: 'does not include', type: 'select' },
  { value: 'startsWith', label: 'starts with', type: 'input' },
  { value: 'doesNotContain', label: 'does not contain', type: 'input' },
  { value: 'contains', label: 'contains', type: 'input' },
  { value: 'isBlank', label: 'is blank', type: 'blank' },
  { value: 'exact', label: 'equals', type: 'date' },
  { value: 'after', label: 'after', type: 'date' },
  { value: 'before', label: 'before', type: 'date' },
  { value: 'between', label: 'between', type: 'date' },
  { value: 'isCurrent', label: 'is current', type: 'blank' },
  { value: 'isNotCurrent', label: 'is not current', type: 'blank' },
];

export const KandjiViews = [
  {
    id: '21f5dabe-80f7-47df-9e86-51ed4543205f',
    name: 'Offline Devices',
    columns: [
      'id',
      'status',
      'computerName',
      'model',
      'serial',
      'blueprint',
      'assetUser',
      'lastCheckIn',
    ],
    filters: [{ name: 'status', value: ['MISSING'], operator: 'equals' }],
    sorting: { sortName: 'lastCheckIn', sortOrder: 'desc' },
    percentWidths: {
      lastCheckIn: 17,
      assetUser: 17,
      computerName: 17,
      blueprint: 17,
      model: 17,
      serial: 17,
    },
  },
  {
    id: 'f8c9b8f9-0a90-4045-bd22-4049cf9fa497',
    name: 'Devices With Alerts',
    columns: [
      'id',
      'status',
      'computerName',
      'model',
      'serial',
      'blueprint',
      'assetUser',
      'lastCheckIn',
    ],
    filters: [{ name: 'status', value: ['WARNING,ERROR'], operator: 'equals' }],
    sorting: { sortName: 'computerName', sortOrder: 'asc' },
    percentWidths: {
      lastCheckIn: 17,
      assetUser: 17,
      computerName: 17,
      blueprint: 17,
      model: 17,
      serial: 17,
    },
  },
  {
    id: '085eb3fc-9b66-4b05-95e4-61c82a8eebe1',
    name: 'No User Assigned',
    columns: [
      'id',
      'status',
      'computerName',
      'model',
      'serial',
      'blueprint',
      'assetUser',
    ],
    filters: [{ name: 'assetUser', value: null, operator: 'isBlank' }],
    sorting: { sortName: 'computerName', sortOrder: 'asc' },
    percentWidths: {
      assetUser: 20,
      computerName: 20,
      blueprint: 20,
      model: 20,
      serial: 20,
    },
  },
  {
    id: '3caacb1e-1dd7-497e-b9da-54a46ff78310',
    name: 'No Asset Tag Assigned',
    columns: [
      'id',
      'status',
      'computerName',
      'model',
      'serial',
      'blueprint',
      'assetUser',
      'assetTag',
    ],
    filters: [{ name: 'assetTag', value: null, operator: 'isBlank' }],
    sorting: { sortName: 'computerName', sortOrder: 'asc' },
    percentWidths: {
      assetUser: 17,
      computerName: 17,
      blueprint: 17,
      model: 17,
      serial: 17,
      assetTag: 17,
    },
  },
];

export const DEPViews = [
  {
    id: 'total',
    sidebarTitle: 'Devices',
    name: 'Automated Device Enrollment Devices',
    columns: [
      'id',
      'status',
      'computerName',
      'serial',
      'model',
      'description',
      'blueprint',
      'assetUser',
    ],
    filters: [],
    sorting: { sortName: 'computerName', sortOrder: 'desc' },
    percentWidths: {
      computerName: 14,
      serial: 14,
      model: 14,
      description: 14,
      blueprint: 14,
      assetTag: 14,
      assetUser: 14,
    },
  },
];

export const columnOptions = {
  agentVersion: {
    name: 'agentVersion',
    value: 'Agent Version',
    backendValue: 'agent_version',
  },
  assetTag: {
    name: 'assetTag',
    value: 'Asset Tag',
    backendValue: 'asset_tag',
  },
  assetUser: { name: 'assetUser', value: 'User', backendValue: 'user' },
  device_assigned_date: {
    name: 'device_assigned_date',
    value: 'Assigned to Kandji',
    backendValue: 'device_assigned_date',
  },
  DEPProfileAssignmentStatus: {
    name: 'DEPProfileAssignmentStatus',
    value: 'Profile',
    backendValue: 'last_assignment_status',
  },
  blueprint: {
    name: 'blueprint',
    value: 'Blueprint',
    backendValue: 'blueprint',
  },
  model: { name: 'model', value: 'Model', backendValue: 'model' },
  deviceFamily: {
    name: 'deviceFamily',
    value: 'Device Family',
    backendValue: 'device_family',
  },
  serial: {
    name: 'serial',
    value: 'Serial',
    backendValue: 'serial_number',
  },
  description: {
    name: 'description',
    value: 'Description',
    backendValue: 'description',
  },
  enrollmentStatus: {
    name: 'enrollmentStatus',
    value: 'Enrollment Status',
    backendValue: 'enrollment_status',
  },
  color: { name: 'color', value: 'Color', backendValue: 'color' },
  deviceAssignedBy: {
    name: 'deviceAssignedBy',
    value: 'Assigned By',
    backendValue: 'device_assigned_by',
  },
  firstEnrollment: {
    name: 'firstEnrollment',
    value: 'First Enrollment',
    backendValue: 'first_enrolled_at',
  },
  lastEnrollment: {
    name: 'lastEnrollment',
    value: 'Last Enrollment',
    backendValue: 'enrolled_at',
  },
  lastCheckIn: {
    name: 'lastCheckIn',
    value: 'Last Checked In',
    backendValue: 'last_seen_dt',
  },
  OS: { name: 'OS', value: 'OS Version', backendValue: 'os_version' },
  is_mdm_enabled: {
    name: 'is_mdm_enabled',
    value: 'MDM Enabled',
    backendValue: 'is_mdm_enabled',
  },
  is_agent_installed: {
    name: 'is_agent_installed',
    value: 'Agent Installed',
    backendValue: 'is_agent_installed',
  },
};

export const colors = {
  danger: '#f05b7e',

  'red-500': '#E52D2E',

  'orange-500': '#f16116',
  'orange-400': '#F27029',
  'orange-300': '#F19C16',

  'yellow-500': '#ffcf01',
  'yellow-400': '#FFD229',
  'yellow-300': '#fccd01',

  'green-500': '#71a75b',
  'green-300': '#bfc45b',

  'aqua-800': '#4575B1',
  'aqua-500': '#5ba6a7',

  'purple-500': '#504EA5',
  'purple-400': '#826AB7',

  'grey-999': '#0f0f0f',
  'grey-900': '#212529',
  'grey-700': '#444',
  'grey-600': '#555',
  'grey-500': '#666',
  'grey-450': '#6c757d',
  'grey-430': '#808080',
  'grey-410': '#909196',
  'grey-405': '#92969e',
  'grey-400': '#999',
  'grey-300': '#b2b2b2',
  'grey-280': '#afb2b8',
  'grey-270': '#bfbfbf',
  'grey-250': '#ccc',
  'grey-230': '#d6d6d6',
  'grey-200': '#dedede',
  'grey-150': '#dee0e4',
  'grey-130': '#e0e0e0',
  'grey-110': '#e6e8ed',
  'grey-80': '#f0f2f7',
  'grey-50': '#f6f6f6',
  'grey-20': '#f0f0f0',

  'marengo-700': '#1B1F29',
  'marengo-699': '#1d242f',
  'marengo-680': '#1f242f',
  'marengo-650': '#292f3e',
  'marengo-630': '#3D4557',
  'marengo-600': '#3B4459',
  'marengo-550': '#424f6c',
  'marengo-500': '#42506B',
  'marengo-400': '#4E5C77',
  'marengo-380': '#595e6b',
  'marengo-350': '#606673',
  'marengo-340': '#646b7a',
  'marengo-330': '#5E677E',
  'marengo-320': '#93969c',
  'marengo-310': '#9498a1',
  'marengo-300': '#888E9D',
  'marengo-200': '#A5AAB5',
  'marengo-120': '#c8ccd5',
  'marengo-110': '#E5E7EF',
  'marengo-100': '#caceda',
  'marengo-90': '#C9CDD9',
  'marengo-70': '#d8deeb',
  'marengo-50': '#dcdfe6',
  'marengo-30': '#e6e9f0',

  red500: '#E52D2E',
  red50: '#fff3f6',

  orange500: '#f16116',
  orange400: '#F27029',
  orange300: '#F19C16',

  yellow500: '#ffcf01',
  yellow400: '#FFD229',
  yellow300: '#fccd01',

  green500: '#71a75b',
  green300: '#bfc45b',

  aqua800: '#4575B1',
  aqua500: '#5ba6a7',
  aqua50: '#e3effe',

  purple500: '#504EA5',
  purple400: '#826AB7',

  grey999: '#0f0f0f',
  grey900: '#212529',
  grey700: '#444',
  grey600: '#555',
  grey500: '#666',
  grey450: '#6c757d',
  grey430: '#808080',
  grey410: '#909196',
  grey405: '#92969e',
  grey400: '#999',
  grey300: '#b2b2b2',
  grey280: '#afb2b8',
  grey270: '#bfbfbf',
  grey250: '#ccc',
  grey230: '#d6d6d6',
  grey200: '#dedede',
  grey150: '#dee0e4',
  grey130: '#e0e0e0',
  grey110: '#e6e8ed',
  grey80: '#f0f2f7',
  grey50: '#f6f6f6',
  grey20: '#f0f0f0',

  marengo700: '#1B1F29',
  marengo699: '#1d242f',
  marengo680: '#1f242f',
  marengo650: '#292f3e',
  marengo630: '#3D4557',
  marengo600: '#3B4459',
  marengo550: '#424f6c',
  marengo500: '#42506B',
  marengo400: '#4E5C77',
  marengo380: '#595e6b',
  marengo350: '#606673',
  marengo340: '#646b7a',
  marengo320: '#93969c',
  marengo310: '#9498a1',
  marengo300: '#888E9D',
  marengo200: '#A5AAB5',
  marengo120: '#c8ccd5',
  marengo100: '#caceda',
  marengo70: '#d8deeb',
  marengo50: '#dcdfe6',
  marengo30: '#e6e9f0',

  white: '#fff',
  black: '#000',

  shark500: '#1a1d25',
  wildSand500: '#f4f4f4',

  'grey-300-transparent-300': '#B7B7B750',

  'button-primary-bg': '#1f242f',
  'button-primary-hover-bg': '#585e6c',

  'button-secondary-bg': '#fff',
  'button-secondary-hover-bg': '#ced1d8',
  'button-secondary-border': '#1f242f',

  'button-secondary-new-bg': '#EEF0F6',
  'button-secondary-new-border': '#1f242f',

  'button-tertiary-border': '#ced1d8',
};

export const theme = {
  colors,
  buttons: {
    regular: {
      backgroundColor: colors['yellow-500'],
      color: colors['marengo-700'],
      hoverColor: colors['marengo-700'],
      hoverBoxShadow: `0 5px 10px ${colors['marengo-699']}33, 0 3px 5px ${colors['marengo-699']}44;`,
    },
    dark: {
      backgroundColor: colors['marengo-700'],
      color: colors['grey-50'],
    },
    modalClear: {
      backgroundColor: 'transparrent',
      background: 'none',
      backgroundColorHover: 'transparrent',
      boxShadow: 'none',
      color: colors.black,
      hoverBoxShadow: 'none',
      hoverColor: colors.black,
      height: '2.5rem',
      fontSize: '12px',
      hoverBackground: 'none',
    },
    modalBack: {
      backgroundColor: 'transparrent',
      background: 'none',
      backgroundColorHover: 'transparrent',
      boxShadow: 'none',
      color: colors.black,
      hoverBoxShadow: 'none',
      hoverColor: colors.black,
      height: '2.5rem',
      fontSize: '12px',
      hoverBackground: 'none',
      margin: '0 0 4px -36px',
    },
    modalDark: {
      backgroundColor: colors['marengo-699'],
      color: colors.white,
      hoverBoxShadow: `0 5px 10px ${colors['marengo-699']}33, 0 3px 5px ${colors['marengo-699']}44;`,
      hoverBackground: colors['marengo-699'],
      height: '2.5rem',
      fontSize: '12px',
    },
    outline: {
      backgroundColor: 'transparrent',
      background: 'none',
      hoverColor: colors.white,
      hoverBoxShadow: 'none',
      hoverBackground: colors['orange-500'],
      height: '2.5rem',
      fontSize: '12px',
      border: `2px solid ${colors['marengo-700']}`,
      hoverBorder: `2px solid ${colors['orange-500']}`,
      hoverTransform: 'none',
    },
  },
};

export const blueprintColors = [
  'red-500',
  'orange-500',
  'orange-400',
  'orange-300',
  'yellow-500',
  'green-300',
  'green-500',
  'aqua-500',
  'aqua-800',
  'purple-500',
  'purple-400',
];

export const blueprintTypes = {
  form: 'form',
  flow: 'flow',
};

export const blueprintIconClasses = [
  'ss-unstar',
  'ss-anchor',
  'ss-thumbnails',
  'ss-twodie',
  'ss-quote',
  'ss-send',
  'ss-umbrella',
  'ss-fax',
  'ss-bone',
  'ss-trash',
  'ss-link',
  'ss-paint',
  'ss-lowvolume',
  'ss-key',
  'ss-searchfile',
  'ss-car',
  'ss-code',
  'ss-addressbook',
  'ss-uploadfolder',
  'ss-tooth',
  'ss-puzzle',
  'ss-keyboardup',
  'ss-ban',
  'ss-lock',
  'ss-bookmark',
  'ss-down',
  'ss-refresh',
  'ss-activity',
  'ss-disc',
  'ss-fastforward',
  'ss-store',
  'ss-print',
  'ss-surprise',
  'ss-creditcard',
  'ss-check',
  'ss-mug',
  'ss-downloadcloud',
  'ss-users',
  'ss-writingdisabled',
  'ss-lodging',
  'ss-airplay',
  'ss-smile',
  'ss-picture',
  'ss-skipback',
  'ss-femaleuser',
  'ss-settings',
  'ss-piechart',
  'ss-alert',
  'ss-cart',
  'ss-toolbox',
  'ss-outbox',
  'ss-newspaper',
  'ss-viewdisabled',
  'ss-camera',
  'ss-partlycloudy',
  'ss-unheart',
  'ss-plane',
  'ss-chat',
  'ss-utensils',
  'ss-alarmclock',
  'ss-burger',
  'ss-write',
  'ss-navigate',
  'ss-sync',
  'ss-notificationsdisabled',
  'ss-rewind',
  'ss-compose',
  'ss-dashboard',
  'ss-checkitem',
  'ss-battery',
  'ss-search',
  'ss-star',
  'ss-calculator',
  'ss-picnictable',
  'ss-magnet',
  'ss-fourdie',
  'ss-calendar',
  'ss-shoppingbag',
  'ss-buildings',
  'ss-calculate',
  'ss-phone',
  'ss-mutevolume',
  'ss-flashoff',
  'ss-film',
  'ss-pointright',
  'ss-downright',
  'ss-flashlight',
  'ss-loading',
  'ss-pixels',
  'ss-user',
  'ss-globe',
  'ss-window',
  'ss-chickenleg',
  'ss-record',
  'ss-up',
  'ss-coffee',
  'ss-emptybattery',
  'ss-crescentmoon',
  'ss-mic',
  'ss-bus',
  'ss-paintedit',
  'ss-tv',
  'ss-list',
  'ss-home',
  'ss-counterclockwise',
  'ss-files',
  'ss-rows',
  'ss-lamp',
  'ss-info',
  'ss-desktop',
  'ss-lowbattery',
  'ss-theatre',
  'ss-upload',
  'ss-grid',
  'ss-upleft',
  'ss-clipboard',
  'ss-headphones',
  'ss-redirect',
  'ss-radio',
  'ss-typewriter',
  'ss-move',
  'ss-inbox',
  'ss-right',
  'ss-crop',
  'ss-backspace',
  'ss-mediumbattery',
  'ss-expand',
  'ss-scaleup',
  'ss-tag',
  'ss-pictures',
  'ss-laptop',
  'ss-ghost',
  'ss-compass',
  'ss-threedie',
  'ss-volume',
  'ss-floppydisk',
  'ss-toggles',
  'ss-pencilbrushpen',
  'ss-lifepreserver',
  'ss-additem',
  'ss-highvolume',
  'ss-attach',
  'ss-transfer',
  'ss-tablet',
  'ss-location',
  'ss-navigateup',
  'ss-ruler',
  'ss-phonedisabled',
  'ss-barchart',
  'ss-filecabinet',
  'ss-downloadfolder',
  'ss-unlock',
  'ss-stopwatch',
  'ss-tree',
  'ss-paperbag',
  'ss-plus',
  'ss-train',
  'ss-dislike',
  'ss-highbattery',
  'ss-upright',
  'ss-pushpin',
  'ss-clock',
  'ss-navigatedown',
  'ss-presentation',
  'ss-mailbox',
  'ss-flatscreen',
  'ss-voicemail',
  'ss-target',
  'ss-lightbulb',
  'ss-heart',
  'ss-tea',
  'ss-cloud',
  'ss-plug',
  'ss-flashlighton',
  'ss-like',
  'ss-play',
  'ss-fish',
  'ss-dial',
  'ss-wineglass',
  'ss-share',
  'ss-gridlines',
  'ss-box',
  'ss-clapboard',
  'ss-pause',
  'ss-fivedie',
  'ss-caution',
  'ss-contacts',
  'ss-notebook',
  'ss-cell',
  'ss-folder',
  'ss-fence',
  'ss-action',
  'ss-robot',
  'ss-bird',
  'ss-help',
  'ss-left',
  'ss-binoculars',
  'ss-inboxes',
  'ss-ellipsis',
  'ss-map',
  'ss-batterydisabled',
  'ss-textchat',
  'ss-droplet',
  'ss-mail',
  'ss-keyboard',
  'ss-pointdown',
  'ss-books',
  'ss-filter',
  'ss-birdhouse',
  'ss-uploadcloud',
  'ss-shredder',
  'ss-sixdie',
  'ss-contract',
  'ss-signpost',
  'ss-notifications',
  'ss-outlet',
  'ss-cut',
  'ss-hyphen',
  'ss-replay',
  'ss-wifi',
  'ss-flask',
  'ss-file',
  'ss-paintdisabled',
  'ss-wrench',
  'ss-sun',
  'ss-bike',
  'ss-downleft',
  'ss-eject',
  'ss-navigateright',
  'ss-bank',
  'ss-banknote',
  'ss-crosshair',
  'ss-view',
  'ss-pointleft',
  'ss-ellipsischat',
  'ss-paperairplane',
  'ss-pin',
  'ss-stop',
  'ss-skipforward',
  'ss-onedie',
  'ss-taxi',
  'ss-rocket',
  'ss-briefcase',
  'ss-video',
  'ss-scaledown',
  'ss-pointup',
  'ss-columns',
  'ss-sample',
  'ss-zoomin',
  'ss-download',
  'ss-flash',
  'ss-zoomout',
  'ss-flag',
  'ss-navigateleft',
  'ss-cursor',
  'ss-delete',
  'ss-music',
  'ss-truck',
  'ss-keyboarddown',
  'ss-menu',
  'ss-paintroller',
];

/* istanbul ignore next */
export const activityTypeLists = {
  get global() {
    return [
      { value: 'ALL', label: i18n.t('All Types') },
      {},
      { value: 'blueprint_created', label: i18n.t('Blueprint Created') },
      {
        value: 'blueprint_name_changed',
        label: i18n.t('Blueprint Name Changed'),
      },
      { value: 'parameters_changed', label: i18n.t('Parameters Changed') },
      { value: 'computer_enrollment', label: i18n.t('Device Enrollment') },
      { value: 'mdm_profile_renewed', label: i18n.t('MDM Profile Renewal') },
      { value: 'runs_after_enrollment', label: i18n.t('Parameters First Run') },
      { value: 'move_between_blueprints', label: i18n.t('Blueprint Changed') },
      { value: 'computer_gone_missing', label: i18n.t('Device Offline') },
      { value: 'computer_gone_active', label: i18n.t('Device Active') },
      {},
      { value: 'remediation_obtained', label: i18n.t('Remediations') },
      {
        value: 'first_run_remediation',
        label: i18n.t('First Run Remediations'),
      },
      { value: 'application_blocked', label: i18n.t('Application Blocked') },
    ];
  },
  get blueprint() {
    return [
      { value: 'ALL', label: i18n.t('All Types') },
      {},
      { value: 'computer_enrollment', label: i18n.t('Device Enrollment') },
      { value: 'parameters_changed', label: i18n.t('Parameters Changed') },
      {
        value: 'blueprint_name_changed',
        label: i18n.t('Blueprint Name Changed'),
      },
      {
        value: 'blueprint_desc_changed',
        label: i18n.t('Blueprint Description Modified'),
      },
      {
        value: 'computer_into_blueprint',
        label: i18n.t('Device Moved Into Blueprint'),
      },
      {
        value: 'computer_out_of_blueprint',
        label: i18n.t('Device Moved Out Of Blueprint'),
      },
      { value: 'computer_deletion', label: i18n.t('Device Deletion') },
      { value: 'blueprint_duplicated', label: i18n.t('Blueprint Duplicated') },
      { value: 'blueprint_notes_created', label: i18n.t('Note Created') },
      { value: 'blueprint_notes_edited', label: i18n.t('Note Edited') },
      { value: 'blueprint_notes_deleted', label: i18n.t('Note Deleted') },
      { value: 'blueprint_created', label: i18n.t('Blueprint Created') },
      { value: 'computer_gone_missing', label: i18n.t('Device Offline') },
      { value: 'computer_gone_active', label: i18n.t('Device Active') },
      {},
      { value: 'remediation_obtained', label: i18n.t('Remediations') },
      {
        value: 'first_run_remediation',
        label: i18n.t('First Run Remediations'),
      },
      { value: 'application_blocked', label: i18n.t('Application Blocked') },
    ];
  },
  get computer() {
    return [
      { value: 'ALL', label: i18n.t('All Types') },
      {},
      { value: 'move_between_blueprints', label: i18n.t('Blueprint Changed') },
      { value: 'agent_upgrades', label: i18n.t('Agent Upgrades') },
      { value: 'os_upgrades', label: i18n.t('OS Upgrades') },
      {
        value: 'deletion_of_param_results',
        label: i18n.t('Deletion Of Param Results'),
      },
      { value: 'computer_notes_created', label: i18n.t('Note Created') },
      { value: 'computer_notes_edited', label: i18n.t('Note Edited') },
      { value: 'computer_notes_deleted', label: i18n.t('Note Deleted') },
      { value: 'enrollment', label: i18n.t('Enrollment') },
      { value: 'enrollment_details', label: i18n.t('Enrollment Details') },
      { value: 'username_changed', label: i18n.t('User Updated') },
      { value: 'asset_tag_changed', label: i18n.t('Asset Tag Updated') },
      {
        value: 'hardware_uuid_changed',
        label: i18n.t('Hardware UUID Changed'),
      },
      {
        value: 'serial_number_changed',
        label: i18n.t('Serial Number Changed'),
      },
      { value: 'local_user_added', label: i18n.t('Local User Added') },
      { value: 'local_user_deleted', label: i18n.t('Local User Deleted') },
      { value: 'local_user_changed', label: i18n.t('Local User Changed') },
      { value: 'name_changed', label: i18n.t('Device Name Updated') },
      {
        value: 'decrypt_file_vault_prk',
        label: i18n.t('Viewed FileVault Recovery Key'),
      },
      {
        value: 'receive_file_vault_prk',
        label: i18n.t('Received FileVault Recovery Key'),
      },
      { value: 'runs_after_enrollment', label: i18n.t('Parameters First Run') },
      { value: 'computer_gone_missing', label: i18n.t('Device Offline') },
      { value: 'computer_gone_active', label: i18n.t('Device Active') },
      {},
      { value: 'remediation_obtained', label: i18n.t('Remediations') },
      {
        value: 'first_run_remediation',
        label: i18n.t('First Run Remediations'),
      },
      { value: 'application_blocked', label: i18n.t('Application Blocked') },
      {
        value: 'device_unlock_pin_viewed',
        label: i18n.t('Device Unlock Pin Viewed'),
      },
      {
        value: 'activation_lock_bypass_code_viewed',
        label: i18n.t('Activation Lock Bypass Code Viewed'),
      },
      {
        value: 'device_based_activation_lock_enabled',
        label: i18n.t('Device Based Activation Lock Enabled'),
      },
    ];
  },
  get app() {
    return [
      { value: 'ALL', label: i18n.t('All Types') },
      {},
      { value: 'library_item_created', label: i18n.t('Library Item Created') },
      { value: 'library_item_edited', label: i18n.t('Library Item Edited') },
      {
        value: 'library_item_assignment_changed',
        label: i18n.t('Library Item Assignment Changed'),
      },
      { value: 'library_item_deleted', label: i18n.t('Library Item Deleted') },
      {
        value: 'library_item_duplicated',
        label: i18n.t('Library Item Duplicated'),
      },
    ];
  },
  get adcs() {
    return [
      { value: 'ALL', label: i18n.t('All Types') },
      {},
      { value: 'adcs_connector_added', label: i18n.t('Connector added') },
      { value: 'adcs_connector_deleted', label: i18n.t('Connector deleted') },
      { value: 'adcs_connector_online', label: i18n.t('Connector online') },
      { value: 'adcs_connector_offline', label: i18n.t('Connector offline') },
      { value: 'adcs_server_added', label: i18n.t('AD CS server added') },
      { value: 'adcs_server_updated', label: i18n.t('AD CS server updated') },
      { value: 'adcs_server_deleted', label: i18n.t('AD CS server deleted') },
      { value: 'adcs_certificate_issued', label: i18n.t('Certificate Issued') },
      {
        value: 'adcs_certificate_issued_failure',
        label: i18n.t('Certificate issuance failed'),
      },
      {
        value: 'adcs_certificate_renewed',
        label: i18n.t('Certificate renewed'),
      },
    ];
  },
};

export const activityPeriodList = [
  {
    value: 'ALL',
    get label() {
      return i18n.t('All Time');
    },
  },
  {},
  {
    value: '1',
    get label() {
      return i18n.t('Past 1 Day');
    },
  },
  {
    value: '7',
    get label() {
      return i18n.t('Past 7 Days');
    },
  },
  {
    value: '30',
    get label() {
      return i18n.t('Past 30 Days');
    },
  },
];

export const statusList = [
  { value: 'ALL', label: 'All Statuses', iconClass: 'fa oa-3c' },
  {},
  {
    value: 'PASS',
    label: 'Passed',
    iconClass: 'fas fa-check-circle text-success',
  },
  {
    value: 'REMEDIATED',
    label: 'Remediated',
    iconClass: 'fas fa-check-square text-light-blue',
  },
  {
    value: 'WARNING,ERROR',
    label: 'Alert',
    iconClass: 'fas fa-exclamation-triangle text-warning',
  },
  {
    value: 'MUTE',
    label: 'Muted Alert',
    iconClass: 'fas fa-exclamation-triangle text-grey',
  },
  {
    value: 'INCOMPATIBLE',
    label: 'Incompatible',
    iconClass: 'fas fa-times-circle text-grey',
  },
  {
    value: 'NO_HISTORY',
    label: 'Not Yet Run',
    iconClass: 'far fa-circle text-grey',
  },
];

const webappLinks = {
  index: '/',
  trial: '/trial',
  signup: '/signup',
  signin: '/signin',
  registration: '/registration',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  alerts: {
    active: '/alerts/active?status[]=WARNING,ERROR&displayType=LAST_CHECKIN',
    activeMuted: '/alerts/activeMuted?status[]=MUTE&displayType=LAST_CHECKIN',
    old: '/alerts/old?status[]=ERROR,WARNING,MUTE&displayType=NOT_LAST_CHECKIN&sortName=ended_at&sortOrder=desc',
  },
  activity: '/activity',
  computers: '/computers',
  devices: '/devices',
  prism: '/devices/prism',
  pulse: '/devices/pulse',
  depDevices: '/devices/auto-enroll',
  blueprints: '/blueprints',
  library: '/library',
  vulnerability: '/vulnerability',
  threat: '/threat',
  addLibrary: '/library/add',
  ipaApps: '/library/ipa-apps',
  vppApps: '/library/app-store-apps',
  automaticApps: '/library/automatic-apps',
  operatingSystems: '/library/operating-systems',
  iOSReleases: '/library/ios-releases',
  iPadOSReleases: '/library/ipados-releases',
  tvOSReleases: '/library/tvos-releases',
  recoveryPassword: '/library/recovery-password',
  compliance: '/compliance',
  customApps: '/library/custom-apps',
  customScripts: '/library/custom-scripts',
  customPrinters: '/library/custom-printers',
  avert: '/library/avert',
  deviceName: '/library/device-name',
  customWifi: '/Library/wifi',
  customVpn: '/Library/vpn',
  customConfiguration: '/library/custom-profile',
  customKernelExtension: '/library/kernel-extensions',
  customAirPrint: '/Library/airprint',
  customAutoEnroll: '/library/auto-enroll',
  customPrivacyProfiles: '/library/privacy',
  customAppLockProfiles: '/library/app-lock',
  customPasscodeProfiles: '/library/passcode',
  customCertificate: '/library/certificate',
  deviceControl: '/library/device-control',
  settings: 'settings',
  userProfile: '/user-profile',
  featureRequest: '/feature-request',
  'integration-users': {
    withDevices: '/users/withDevices?is_archived=false&num_computers__gt=0',
    withoutDevices: '/users/withoutDevices?is_archived=false&num_computers=0',
    archived: '/users/archived?is_archived=true',
  },
  'my-company': '/my-company',
  'add-devices': '/add-devices',
  myCompany: {
    integrations: '/my-company/integrations',
  },
  integrations: {
    root: '/integrations',
    marketplace: '/integrations/marketplace',
    integrationPage: '/integrations/:type',
  },
  logs: '/logs',
  resources: '/resources',

  admin: {
    companies: '/admin/companies',
    computers: '/admin/computers',
    team: {
      team: '/admin/team/team',
      all: '/admin/team/all',
    },
    parameters: '/admin/parameters',
    parameterTags: '/admin/parameter-tags',
    categories: '/admin/categories',
    templates: '/admin/templates',
    errors: '/admin/errors',
    diagnostics: '/admin/diagnostics',
  },
};

export const agentLinks = {
  agentEnrollment: '/enrollment/authentication',
};

export const enrollLinks = {
  computerEnrollment:
    /*istanbul ignore next */
    (code: string) => `/enroll/access-code/${code}`,
  phoneEnrollment:
    /*istanbul ignore next */
    (code: string) => `/enroll/phone/access-code/${code}`,
  tabletEnrollment:
    /*istanbul ignore next */
    (code: string) => `/enroll/tablet/access-code/${code}`,
};

export const kbArticleLinks = {
  manualEnrollmentLearnMore:
    'https://support.kandji.io/support/solutions/articles/72000560543-configuring-device-enrollment',
  ssoNotConfigured:
    'https://support.kandji.io/support/solutions/articles/72000559828-single-sign-on',
  ipaAppsLearnMore:
    'https://support.kandji.io/support/solutions/articles/72000633241',
};

export const adcsLinks = {
  adcsEnrollment: '/adcs/authentication',
};

export const passportLinks = {
  passportAuthentication: '/passport/authentication',
};

export const links = {
  ...adcsLinks,
  ...agentLinks,
  ...passportLinks,
  ...webappLinks,
  ...enrollLinks,
  ...kbArticleLinks,
};

export const http = {
  method: {
    get: 'get',
    post: 'post',
    patch: 'patch',
    delete: 'delete',
  },
};

export const AwesomeTableConstants = {
  columnMinWidth: 50,
};

export const MDMCommandStatus = {
  1: 'Pending', // not sent to device
  2: 'Running', // sent to device but an answer doesn't receive
  3: 'Success',
  4: 'Failed',
  5: 'Not Now (device is busy)',
  6: 'Queued',
};

export const EnrollmentType = {
  STANDARD: 'STANDARD',
  SILENT: 'SILENT',
  AUTO: 'AUTO',
  MDM: 'MDM Manual Enrollment',
  DEP: 'MDM Auto-Enroll (DEP)',
  ADDE: 'Account Driven Device Enrollment',
  ADUE: 'ACcount Driven User Enrollment',
  NOT_FOUND: 'Not Found',
};

export const ProfileTypes = {
  AIRPLAYSECURITY: 'com.kandji.profile.airplaysecurity',
  AIRPRINT: 'com.kandji.profile.airprint',
  APPLOCK: 'com.kandji.profile.applock',
  APPSTORE: 'com.kandji.profile.appstore',
  AUTOENROLL: 'com.kandji.profile.autoenroll',
  CERTIFICATE: 'com.kandji.profile.certificate',
  CONFERENCEROOMDISPLAY: 'com.kandji.profile.conferenceroomdisplay',
  CUSTOM: 'com.kandji.profile.custom',
  ENERGYSAVER: 'com.kandji.profile.energysaver',
  FILEVAULT: 'com.kandji.profile.filevault',
  FIREWALL: 'com.kandji.profile.firewall',
  GATEKEEPER: 'com.kandji.profile.gatekeeper',
  KERNEL: 'com.kandji.profile.kernel',
  LOGINWINDOW: 'com.kandji.profile.loginwindow',
  MEDIAACCESS: 'com.kandji.profile.mediaaccess',
  PASSCODE: 'com.kandji.profile.passwordpolicy',
  PRIVACY: 'com.kandji.profile.privacy',
  RECOVERY_PASSWORD: 'com.kandji.profile.recoverypassword',
  RESTRICTIONS: 'com.kandji.profile.restrictions',
  SCEP: 'com.kandji.profile.scep',
  SCREENSAVER: 'com.kandji.profile.screensaver',
  SOFTWAREUPDATES: 'com.kandji.profile.softwareupdates',
  SSOEXTENSION: 'com.kandji.profile.ssoextension',
  SYSTEMEXTENSION: 'com.kandji.profile.systemextension',
  SYSTEMPREFERENCES: 'com.kandji.profile.systempreferences',
  VPN: 'com.kandji.profile.vpn',
  WEBCLIP: 'com.kandji.profile.webclip',
  WIFI: 'com.kandji.profile.wifi',
};

export const EnrollmentTypes = {
  MDM: { id: 3, value: 'Enrolled via Manual Enrollment' },
  DEP: { id: 4, value: 'Enrolled via Auto-Enroll' },
};

export const AwaitingEnrollment = 'Awaiting Enrollment';

export const LibraryActionTypes = {
  OPTIONAL_TEXTAREA_CHECKBOX_CHANGE: 'OPTIONALTEXTAREA_CHECKBOX_CHANGE',
  OPTIONAL_NUMBER_CHECKBOX_CHANGE: 'OPTIONALNUMBER_CHECKBOX_CHANGE',
  OPTIONAL_SELECT_CHECKBOX_CHANGE: 'OPTIONALSELECT_CHECKBOX_CHANGE',
  OPTIONAL_RADIO_CHECKBOX_CHANGE: 'OPTIONALRADIO_CHECKBOX_CHANGE',
  OPTIONAL_LIST_CHECKBOX_CHANGE: 'OPTIONALLIST_CHECKBOX_CHANGE',
  OPTIONAL_TEXT_CHECKBOX_CHANGE: 'OPTIONALTEXT_CHECKBOX_CHANGE',
  CHECKBOX_CHANGE: 'CHECKBOX_CHECKBOX_CHANGE',
  LIST_ITEM_UPDATE: 'LIST_ITEM_UPDATE',
  LIST_ITEM_ADD: 'LIST_ITEM_ADD',
  LIST_ITEM_REMOVE: 'LIST_ITEM_REMOVE',
};

export const settingNameText = {
  DeviceName: 'Device Name',
  Wallpaper: 'Setting Value',
};

export const minWidthForDockedSidebar = 1280;

export const noUserAssigned = {
  name: 'Not Assigned',
  id: 0,
};

export const billingTypes = {
  CUSTOMER: 'customer',
  INTERNAL: 'internal',
  FREE: 'free',
  APPLE: 'apple',
  TRIAL: 'trial',
  AUTOMATED_TRIAL: 'automated_trial',
  AUTOMATED_BILLING: 'automated_billing',
  PARTNER: 'partner',
};

export const planTypes = {
  SINGLE_LIMIT: 'single_limit',
  PER_PLATFORM_LIMIT: 'per_platform_limit',
};

/**
 * For omitting any vuln-related column names in saved views fetched from API.
 */
export const omitVulnColumns = (featureNames) => {
  const vulnFeatureNames = [
    'number_of_vulnerabilities',
    'highest_vulnerability_score',
    'highest_severity',
  ];
  return featureNames.filter(
    (featureName) => !vulnFeatureNames.includes(featureName),
  );
};
